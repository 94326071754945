<template>
  <div class="form-filter">
    <div class="form-block">
      <div class="form-block-title">Организация</div>

      <div class="flex flex-col fl-1 m-b-8">
        <label for="name" class="small-label">Наименование организации</label>
        <InputText
          id="name"
          placeholder="Введите значение"
          v-model="organization.name"
          :invalid="isInvalidForm && organization.name == ''"
        />
      </div>

      <div class="flex flex-col fl-1 m-b-8">
        <label for="name" class="small-label">Описание организации</label>
        <InputText
          id="name"
          placeholder="Введите значение"
          v-model="organization.description"
        />
      </div>

      <div class="flex flex-col fl-1">
        <label for="name" class="small-label">Tenant</label>
        <InputText
          id="name"
          placeholder="Введите значение"
          v-model="organization.tenant"
          :invalid="isInvalidForm && organization.tenant == ''"
        />
      </div>
    </div>

    <div
      class="form-block"
      v-for="(department, idx) in organization.departments"
      :key="`dep-${idx}`"
    >
      <div class="flex fl-jc-sb flex-center">
        <div class="form-block-title">Департамент</div>
        <div
          class="group-button-container dep flex"
          :class="[idx != organization.departments.length - 1 ? 'hidden' : '']"
        >
          <div
            v-if="organization.departments.length > 1"
            class="delete-button flex flex-center"
            @click="deleteDepartment(idx)"
          >
            <span class="pi pi-trash f-s-12"></span>
          </div>
          <div class="add-button flex flex-center" @click="addDepartment(idx)">
            <span class="pi pi-plus"></span>
          </div>
        </div>
      </div>

      <div class="flex flex-col fl-1 m-b-8">
        <label for="name" class="small-label">Наименование департамента</label>
        <InputText
          id="name"
          placeholder="Введите значение"
          v-model="department.name"
          :invalid="isInvalidForm && department.name == ''"
        />
      </div>

      <div class="sub-block">
        <div class="form-block-title">Должности</div>
        <div
          class="flex flex-col fl-1 m-b-8 role-container"
          v-for="(role, idxr) in department.roles"
          :key="`role-${idxr}`"
        >
          <label for="name" class="small-label">Наименование должности</label>
          <div class="flex flex-center">
            <InputText
              id="name"
              class="fl-1"
              placeholder="Введите значение"
              v-model="role.name"
              :invalid="isInvalidForm && role.name == ''"
            />
            <div
              class="group-button-container role flex m-l-8"
              :class="[
                idxr != organization.departments[idx].roles.length - 1
                  ? 'hidden'
                  : '',
              ]"
            >
              <div
                v-if="organization.departments[idx].roles.length > 1"
                class="delete-button flex flex-center"
                @click="deleteRole(idx, idxr)"
              >
                <span class="pi pi-trash f-s-12"></span>
              </div>
              <div
                class="add-button flex flex-center"
                @click="addRole(idx, idxr)"
              >
                <span class="pi pi-plus"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useOrganizationsStore } from "@/store/dictionaries/organizations";

import InputText from "primevue/inputtext";

import { areOrganizationFieldsValid } from "@/services/dictionaries/organizations";
import { rootGetters } from "@/main";

const emit = defineEmits(["setLoading"]);
const route = useRoute();
const router = useRouter();
const store = useOrganizationsStore();

const organization = ref({
  name: "",
  description: "",
  tenant: "",
  departments: [
    {
      name: "",
      description: "",
      roles: [
        {
          name: "",
          description: "",
        },
      ],
    },
  ],
});

const isInvalidForm = ref(false);
const isOrganizationSetted = computed(() => {
  return Boolean(route.params.id);
});

const reload = () => {
  if (isOrganizationSetted.value) {
    const organizationId = parseInt(route.params.id);
    store.getOrganization(organizationId).then((organizationClone) => {
      if (organizationClone) {
        organization.value = organizationClone;
      }
    });
  }
};

onMounted(() => {
  reload();
});

const addDepartment = (idx: number) => {
  organization.value.departments.splice(idx + 1, 0, {
    name: "",
    description: "",
    roles: [
      {
        name: "",
        description: "",
      },
    ],
  });
};

const deleteDepartment = (idx: number) => {
  organization.value.departments.splice(idx, 1);
};

const addRole = (idxDep: number, idxRole: number) => {
  organization.value.departments[idxDep].roles.splice(idxRole + 1, 0, {
    name: "",
    description: "",
  });
};

const deleteRole = (idxDep: number, idxRole: number) => {
  organization.value.departments[idxDep].roles.splice(idxRole, 1);
};

const createOrganization = () => {
  if (!areOrganizationFieldsValid(organization.value)) {
    isInvalidForm.value = true;
    rootGetters.toast.show(
      "warn",
      "Ошибка сохранения",
      `Проверьте, что все необходимые поля заполнены.`
    );
    return;
  }
  emit("setLoading", true);
  const orgForCreate = {
    organization: {
      name: organization.value.name,
      description: organization.value.description,
      tenant: organization.value.tenant,
    },
    departments: organization.value.departments,
  };

  if (!isOrganizationSetted.value) {
    performOrganizationAction(() => store.createOrganization(orgForCreate));
  } else {
    performOrganizationAction(() =>
      store.updateOrganization(orgForCreate, parseInt(route.params.id))
    );
  }
};

const performOrganizationAction = (action: any) => {
  const handleCampaignSuccess = () => {
    router.push({ name: "listOrganizations" });
    setTimeout(() => {
      store.activeFirst = false;
    }, 5000);
  };

  action()
    .then(handleCampaignSuccess)
    .catch((err: any) => {
      if (err.response && err.response.status == 409)
        rootGetters.toast.show("warn", "Ошибка", err.response.data.detail);
    })
    .finally(() => {
      emit("setLoading", false);
    });
};

defineExpose({
  createOrganization,
});
</script>

<style lang="scss" scoped>
.form-filter {
  max-width: 100%;
  width: 100%;
  padding-bottom: 40px;
  min-width: 500px;
}

.form-block-title {
  margin-bottom: 8px;
}

.form-block:hover .group-button-container.hidden.dep {
  visibility: visible;
}

.role-container:hover .group-button-container.hidden.role {
  display: flex;
}

.small-label {
  color: #a19cab;
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1.43rem;
  padding-bottom: 4px;
  padding-left: 4px;
}

.group-button-container.dep.hidden {
  visibility: hidden;
}

.group-button-container.role.hidden {
  display: none;
}

.delete-button {
  border-radius: 5px;
  background: #f7f7fd;
  width: 32px;
  height: 32px;
  padding-left: 9px;
  cursor: pointer;

  &:hover {
    background-color: #fafaff;
  }
}

.add-button {
  background-color: #dbdaf9;
  border-radius: 5px;
  padding: 3px 9px;
  cursor: pointer;
  height: 32px;
  margin-left: 12px;

  &:hover {
    background-color: #d4d3f8;

    outline-color: transparent;
  }
}

.f-s-12 {
  font-size: 12px;
}

.sub-block {
  padding: 12px;
  background-color: #f7f7fd;
  border: 1px solid #d0d0d6;
  border-radius: 6px;
}
</style>
