import { UsersParams } from "@/store/users/users";

export function setUsersParamsByFilter(params: UsersParams, settings: TableSettings) {
    if (settings.orderField) {
        params.order_by = settings.orderField;
    } else {
        params.order_by = '-mtime';
    }

    if (settings.commonFilter) {
        params.search = settings.commonFilter;
    }

    if (Object.keys(settings.filter).length > 0) {
        if (settings.filter.organization.length > 0) {
            params.organizations__id__in = settings.filter.organization.join(",");
        }

        if (settings.filter.department.length > 0) {
            params.departments__id__in = settings.filter.department.join(",");
        }

        if (settings.filter.role.length > 0) {
            params.roles__id__in = settings.filter.role.join(",");
        }
    }
}

export function isFilledUser(user: User, checkPassword: boolean): boolean {
    const { fname, lname, organization, department, role, email, password } = user;

    return !!fname &&
        !!lname &&
        organization !== null &&
        department !== null &&
        role !== null &&
        !!email &&
        (!!password || !checkPassword);
}


interface TableSettings {
    first: number;
    orderField: string;
    commonFilter: string;
    filter: FilterForm;
}

interface User {
    fname: string,
    lname: string,
    organization: number | null,
    department: number | null,
    role: number | null,
    email: string,
    password: string,
}

export interface FilterForm {
    organization: number[];
    department: number[];
    role: number[];
}