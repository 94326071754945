/**
 * Обертка axios для работы с API
 */

import axios, { AxiosInstance, AxiosResponse } from "axios";
import router from "@/router";
import { useAuthStore } from "@/store/auth/login";

let isRefreshingToken = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: null | string = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

export default class ApiClient {
    client: AxiosInstance;
    defaultHeaders: object;

    constructor(options: any = {}) {
        this.defaultHeaders = options.headers || {
            Accept: "application/json",
            "Content-Type": "application/json",
        };

        this.client =
            options.client ||
            axios.create({
                baseURL: process.env.VUE_APP_API_ENDPOINT,
                headers: this.defaultHeaders,
            });

        this.handleSuccessRequest = this.handleSuccessRequest.bind(this);

        this.client.interceptors.response.use(
            this.handleSuccessResponse,
            this.handleErrorResponse
        );
        this.client.interceptors.request.use(
            this.handleSuccessRequest,
            this.handleErrorRequest
        );
    }

    handleSuccessResponse(response: any) {
        return response || {};
    }

    handleErrorResponse(error: any) {
        if (
            error.response &&
            error.response.status === 401 &&
            error.config &&
            !error.config.__isRetryRequest
        ) {
            const store = useAuthStore();

            if (router.currentRoute.value.name != "login") {
                const originalRequest = error.config;

                if (isRefreshingToken) {
                    return new Promise(function (resolve, reject) {
                        failedQueue.push({ resolve, reject });
                    }).then(token => {
                        originalRequest.headers['Authorization'] = 'Bearer ' + token;
                        return axios(originalRequest);
                    }).catch(err => {
                        return Promise.reject(err);
                    });
                }

                originalRequest.__isRetryRequest = true;
                isRefreshingToken = true;

                return new Promise(function (resolve, reject) {
                    store.refresh_token().then(() => {
                        originalRequest.headers['Authorization'] = 'Bearer ' + localStorage.getItem("access_token");
                        processQueue(null, localStorage.getItem("access_token"));
                        resolve(axios(originalRequest));
                    }).catch((err) => {
                        processQueue(err);

                        store.removeTokens().then(() => {
                            router.push({
                                name: "login",
                                query: { next: router.currentRoute.value.fullPath },
                            });
                            // location.reload();
                        })
                    }).finally(() => {
                        isRefreshingToken = false;
                    });
                })

            }
            return Promise.resolve("");
        }

        return Promise.reject(error);
    }

    handleSuccessRequest(config: any) {
        if (!localStorage.getItem("access_token")) return config;
        const newHeaders = {
            ...this.defaultHeaders,
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        };
        return {
            ...config,
            headers: newHeaders,
        };
    }

    handleErrorRequest(error: any) {
        return Promise.reject(error);
    }

    async proceedRequest(
        method: { method: string; url: string },
        args: any = {},
        params: any = {},
        data: any = {}
    ): Promise<AxiosResponse> {
        return {} as AxiosResponse;
    }
}
