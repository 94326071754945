<template>
  <ListItemsWithTableLayout>
    <template #body>
      <HandlerAccessDenied :is-denied="isDenied">
        <UsersFilterForm
          :isFilterEnabled="isFilterEnabled"
          @toggle-filter="isFilterEnabled = !isFilterEnabled"
          @set-filter="setFilter"
        />
        <TopListUsersPanel
          @set-common-filter="setCommonFilter"
          @toggle-filter="isFilterEnabled = !isFilterEnabled"
          :isFilterEnabled="isFilterEnabled"
          :isFilterSetted="isFilterSetted"
        ></TopListUsersPanel>
        <div
          id="table-container"
          class="table-container flex flex-col"
          :class="[
            filteredUsers && filteredUsers.length == 0 ? 'empty' : 'filled',
          ]"
        >
          <UsersTable
            :users="filteredUsers"
            :isActiveFirst="activeFirstFromStore"
            :isLoading="isLoading"
            :rowsCount="rowsCount(43) * tableDisplaySettings.first"
            @sort-table="sortTable"
            @update-table="updateUsersList"
          ></UsersTable>
          <EmptyTableMessage
            v-if="filteredUsers && filteredUsers.length == 0 && !isLoading"
          >
            <template #text>
              Примените другой фильтр или создайте первого пользователя. <br />
              Для этого кликните на кнопку "Создать пользователя".
            </template>
          </EmptyTableMessage>
        </div>
        <Paginator
          v-model:first="tableDisplaySettings.first"
          @update:first="updateUsersList"
          :rows="1"
          class="s-paginator"
          :totalRecords="store.usersPagesCount"
          template="PrevPageLink CurrentPageReport NextPageLink"
          currentPageReportTemplate="{first} из {totalRecords}"
        />
      </HandlerAccessDenied>
    </template>
  </ListItemsWithTableLayout>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";

import ListItemsWithTableLayout from "@/widgets/layouts/ListItemsWithTableLayout.vue";
import TopListUsersPanel from "@/entities/dictionaries/users/panels/TopListUsersPanel.vue";
import Paginator from "primevue/paginator";
import EmptyTableMessage from "@/entities/segments/EmptyTableMessage.vue";
import UsersTable from "@/entities/dictionaries/users/UsersTable.vue";
import UsersFilterForm from "@/entities/dictionaries/users/forms/UsersFilterForm.vue";
import HandlerAccessDenied from "@/shared/base/HandlerAccessDenied.vue";

import { rowsCount } from "@/services/utils/Pagination";
import { useUsersStore, UsersParams } from "@/store/users/users";
import {
  setUsersParamsByFilter,
  FilterForm,
} from "@/services/dictionaries/users";
import { useAuthStore } from "@/store/auth/login";

const isFilterEnabled = ref(false);
const isFilterSetted = ref(false);
const isLoading = ref(false);
const authStore = useAuthStore();
const tableDisplaySettings = ref({
  first: 0,
  orderField: "",
  commonFilter: "",
  filter: {} as FilterForm,
});
const store = useUsersStore();
const filteredUsers = computed(() => {
  return store.usersList;
});

const isDenied = computed(() => {
  return authStore.isDeniedForPermissions(
    ["system:read", "system:edit"],
    ["users:read", "users:edit"],
    ["dictionaries:read", "dictionaries:edit"]
  );
});

const activeFirstFromStore = computed(() => store.activeFirst);

onMounted(() => {
  if (isDenied.value) return;
  loadData();
});

const loadData = () => {
  isLoading.value = true;
  updateUsersList();
};

const setCommonFilter = (filter: string) => {
  tableDisplaySettings.value.commonFilter = filter;
  updateUsersList();
};

const setFilter = (filter: FilterForm, isSetted: boolean) => {
  tableDisplaySettings.value.filter = filter;
  isFilterSetted.value = isSetted;
  updateUsersList();
};

const sortTable = (order_by: string) => {
  tableDisplaySettings.value.orderField = order_by;
  updateUsersList();
};

const updateUsersList = () => {
  let isEnded = false;
  setTimeout(() => {
    if (!isEnded) {
      isLoading.value = true;
    }
  }, 200);

  const params: UsersParams = {
    page: tableDisplaySettings.value.first + 1,
    per_page: rowsCount(43, 20),
  };

  setUsersParamsByFilter(params, tableDisplaySettings.value);

  store.getUsers(params).finally(() => {
    isLoading.value = false;
    isEnded = true;
  });
};
</script>
