import { defineStore } from "pinia";
import { rootGetters } from "@/main";
import { UserInfo } from "../auth/login";
import { moveItemToFront } from "@/services/utils/Arrays"

const endpoints = {
    changePassword: { method: "patch", url: "/users/me/change-password" },
    getCurrentUser: { method: "get", url: "/users/me" },
    getUsers: { method: "get", url: "/users" },
    editCurrentUser: { method: "patch", url: "/users/me" },
    updateUser: { method: "patch", url: "/users/{user_id}" },
    getUser: { method: "get", url: "/users/{user_id}" },
    createUser: { method: "post", url: "/users" },
};

export const useUsersStore = defineStore("users", {
    state: () => ({
        usersList: [] as UserInfo[],
        usersCount: 0,
        userPerPage: 1,
        activeFirst: false,
        addedUserId: 0,
    }),
    getters: {
        usersPagesCount: (state) => {
            return Math.ceil(state.usersCount / state.userPerPage)
        }
    },
    actions: {
        async changePassword(password: PasswordForm) {
            await rootGetters.api
                .proceedRequest(endpoints.changePassword, {}, {}, password)
                .catch((err) => {
                    return Promise.reject(err);
                })
        },
        async getCurrentUser() {
            return await rootGetters.api
                .proceedRequest(endpoints.getCurrentUser)
                .then((response) => {
                    return response.data;
                });
        },
        async editCurrentUser(form: FiForm) {
            await rootGetters.api
                .proceedRequest(endpoints.editCurrentUser, {}, {}, form)
        },
        async getUsers(params: UsersParams = { page: 1, per_page: 20 }) {
            await rootGetters.api.proceedRequest(
                endpoints.getUsers, {}, params
            ).then((response) => {
                this.usersList = response.data;
                if (this.usersList) {
                    this.userPerPage = parseInt(response.headers["x-per-page"]);
                    this.usersCount = parseInt(response.headers["x-total-count"]);

                    if (this.addedUserId > 0) {
                        console.log(this.addedUserId)
                        moveItemToFront(this.usersList, this.addedUserId);
                        this.addedUserId = 0;
                    }
                }
            });
        },
        async createUser(user: UserCreate) {
            await rootGetters.api
                .proceedRequest(endpoints.createUser, {}, {}, user)
                .then((response) => {
                    if (response) {
                        this.addedUserId = response.data.id;
                        this.activeFirst = true;

                        rootGetters.toast.show(
                            "info",
                            "Создание",
                            `Пользователь успешно создан.`
                        );
                    }
                }).catch((err) => {
                    return Promise.reject(err);
                })
        },
        async updateUser(user: UserUpdate, user_id: number) {
            await rootGetters.api
                .proceedRequest(endpoints.updateUser, { user_id: user_id }, {}, user)
                .then((response) => {
                    if (response) {
                        this.addedUserId = response.data.id;
                        this.activeFirst = true;

                        rootGetters.toast.show(
                            "info",
                            "Создание",
                            `Пользователь успешно обновлен.`
                        );
                    }
                }).catch((err) => {
                    return Promise.reject(err);
                })
        },
        async getUser(userId: number) {
            return await rootGetters.api
                .proceedRequest(endpoints.getUser, { user_id: userId })
                .then((response) => {
                    return response.data;
                });
        },
    }
})

export interface PasswordForm {
    old_password: string,
    new_password: string
}

interface FiForm {
    lname: string;
    fname: string;
}

export interface UsersParams {
    page: number;
    per_page: number;
    fname__ilike?: string;
    lname__ilike?: string;
    search?: string;

    order_by?: string;

    departments__id__in?: string;
    organizations__id__in?: string;
    roles__id__in?: string;
}

interface UserUpdate {
    fname: string,
    lname: string,
    email: string,
    role_id: number | null
}

interface UserCreate extends UserUpdate {
    password: string,
}