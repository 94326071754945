import ApiClient from "./ApiClient";
import { rootGetters } from "@/main";

const nonDefaultExceptions = [409, 400, 401, 403]

function replaceUrlPlaceholders(url: string, args: Record<string, any>): string {
    return url.replace(/{(.*?)}/g, (_, key) => {
        return encodeURIComponent(args[key]);
    });
}

export default new Proxy(new ApiClient(), {
    get: function (target, name) {
        if (name == "proceedRequest") {
            return (
                options: { method: string; url: string },
                args: Record<string, any> = {},
                params: Record<string, any> = {},
                data: Record<string, any> = {}
            ) => {

                const url = replaceUrlPlaceholders(options.url, args);

                return target
                    .client({
                        method: options.method,
                        url: url,
                        data: data,
                        params: params,
                    })
                    .then((response) => {
                        return response;
                    })
                    .catch((err) => {
                        if (!(err.response && nonDefaultExceptions.includes(err.response.status))) {
                            if (err.response && err.response.status == 422 && err.response.data && err.response.data.detail)
                                rootGetters.errorHandler.showError(err, err.response.data.detail[0].msg);
                            else
                                rootGetters.errorHandler.showError(err);
                        }
                        return Promise.reject(err);
                    });
            };
        }
        return (target as any)[name];
    },
});
