import { defineStore } from "pinia";
import { rootGetters } from "@/main";

import { moveItemToFront } from "@/services/utils/Arrays"

const endpoints = {
    getAdsCabinets: { method: "get", url: "/ads_cabinets" },
    getAdsCabinet: { method: "get", url: "/ads_cabinets/{ads_cabinet_id}" },
    deleteAdsCabinet: { method: "delete", url: "/ads_cabinets/{ads_cabinet_id}" },
    createAdsCabinet: { method: "post", url: "/ads_cabinets" },
    updateAdsCabinet: { method: "put", url: "/ads_cabinets/{ads_cabinet_id}" },
};

export const useAdsCabinetsStore = defineStore("ads_cabinets", {
    state: () => ({
        adsCabinetsList: [] as AdsCabinetFromServer[],
        adsCabinetsCount: 0,
        adsCabinetsPerPage: 1,
        activeFirst: false,
        addedAdsCabinetId: 0,
    }),
    getters: {
        adsCabinetsPagesCount: (state) => {
            return Math.ceil(state.adsCabinetsCount / state.adsCabinetsPerPage)
        }
    },
    actions: {
        async createAdsCabinet(adsCabinet: AdsCabinetForCreate) {
            await rootGetters.api
                .proceedRequest(endpoints.createAdsCabinet, {}, {}, adsCabinet)
                .then((response) => {
                    if (response) {
                        this.addedAdsCabinetId = response.data.id;
                        this.activeFirst = true;

                        rootGetters.toast.show(
                            "info",
                            "Создание",
                            `Рекламный кабинет "${response.data.name}" успешно сохранен в раздел "Список рекламных кабинетов".`
                        );
                    }
                }).catch((err) => {
                    return Promise.reject(err);
                })
        },
        async getAdsCabinet(adsCabinetId: number) {
            return await rootGetters.api
                .proceedRequest(endpoints.getAdsCabinet, { ads_cabinet_id: adsCabinetId })
                .then((response) => {
                    return response.data;
                });
        },
        async getAdsCabinets(params: AdsCabinetsParams = { page: 1, per_page: 20 }) {
            await rootGetters.api.proceedRequest(
                endpoints.getAdsCabinets, {}, params
            ).then((response) => {
                this.adsCabinetsList = response.data;
                if (this.adsCabinetsList) {
                    this.adsCabinetsPerPage = parseInt(response.headers["x-per-page"]);
                    this.adsCabinetsCount = parseInt(response.headers["x-total-count"]);

                    if (this.addedAdsCabinetId > 0) {
                        moveItemToFront(this.adsCabinetsList, this.addedAdsCabinetId);
                        this.addedAdsCabinetId = 0;
                    }
                }
            });
        },
        async updateAdsCabinet(adsCabinet: AdsCabinetForUpdate, adsCabinetId: number) {
            await rootGetters.api
                .proceedRequest(endpoints.updateAdsCabinet, { ads_cabinet_id: adsCabinetId }, {}, adsCabinet)
                .then((response) => {
                    if (response) {
                        this.addedAdsCabinetId = response.data.id;
                        this.activeFirst = true;

                        rootGetters.toast.show(
                            "info",
                            "Обновление",
                            `Рекламный кабинет "${response.data.name}" успешно обновлен.`
                        );
                    }
                }).catch((err) => {
                    return Promise.reject(err);
                })
        },
        async deleteAdsCabinet(adsCabinetId: number) {
            await rootGetters.api
                .proceedRequest(endpoints.deleteAdsCabinet, {
                    ads_cabinet_id: adsCabinetId,
                })
        },
    }
})

export interface BaseAdsCabinet {
    name: string;
    login: string;
    source_slug: string | null;
    is_statistics_required: boolean;
}

export interface AdsCabinetForCreate extends BaseAdsCabinet {
    token: string;
}

export interface AdsCabinetForUpdate extends BaseAdsCabinet {
    token?: string;
}

export interface AdsCabinetFromServer extends BaseAdsCabinet {
    id: number;
    source: Source
}

interface Source {
    slug: string,
    name: string,
    is_platform: boolean
}

export interface AdsCabinetsParams {
    page: number;
    per_page: number;
    name?: string;
    name__ilike?: string;
    source_slug__in?: string;
    is_statistics_required?: boolean;
    id?: number;

    order_by?: string;
}

