<template>
  <div class="form-filter">
    <div class="form-block">
      <div class="form-block-title">Данные для авторизации</div>
      <div class="flex some-inputs m-t-8">
        <div
          class="flex flex-col"
          :class="[isUserSetted ? 'fl-1' : 'input-container m-r-20']"
        >
          <label class="small-label">Логин/почта</label>
          <InputText
            placeholder="Введите значение"
            autocomplete="off"
            :invalid="isInvalidForm && user.email == ''"
            v-model="user.email"
          />
        </div>
        <div class="flex flex-col input-container" v-if="!isUserSetted">
          <label class="small-label">Пароль</label>
          <div class="flex">
            <IconField iconPosition="right" class="m-r-8 fl-1">
              <InputIcon
                class="pi pointer"
                :class="[passwordClosed ? 'pi-eye' : 'pi-eye-slash']"
                @click="passClick"
              >
              </InputIcon>
              <InputText
                :class="[passwordClosed ? 'closed' : 'opened']"
                class="w-100"
                placeholder="Введите значение"
                autocomplete="off"
                :invalid="isInvalidForm && user.password == ''"
                v-model="user.password"
              />
            </IconField>
            <Button
              icon="pi pi-bolt"
              class="purple-button"
              aria-label="Сгенерировать пароль"
              @click="passGenerate"
              v-tooltip.bottom="{
                value: 'Сгенерировать пароль',
                class: 'white-tooltip',
              }"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="form-block">
      <div class="form-block-title bold">Личные данные</div>
      <div class="flex some-inputs m-t-8">
        <div class="flex flex-col m-r-20 input-container">
          <label class="small-label">Фамилия</label>
          <InputText
            placeholder="Введите значение"
            v-model="user.lname"
            :invalid="isInvalidForm && user.lname == ''"
          />
        </div>
        <div class="flex flex-col input-container">
          <label class="small-label">Имя</label>
          <InputText
            placeholder="Введите значение"
            v-model="user.fname"
            :invalid="isInvalidForm && user.fname == ''"
          />
        </div>
      </div>
    </div>

    <div class="form-block">
      <div class="form-block-title bold">Организация пользователя</div>

      <div class="flex some-inputs m-t-8">
        <div class="flex flex-col m-r-20 input-container">
          <label class="small-label">Организация</label>
          <CatalogDataSingleInput
            :useIdOnly="true"
            :with-reload="true"
            selectType="organization"
            :is-invalid="isInvalidForm && user.organization == null"
            :isSegmentSetted="false"
            :modelValue="user.organization"
            @update:modelValue="($event) => (user.organization = $event)"
          ></CatalogDataSingleInput>
        </div>
        <div class="flex flex-col input-container">
          <label class="small-label">Департамент</label>
          <Dropdown
            v-model="user.department"
            :invalid="isInvalidForm && user.department == null"
            optionLabel="name"
            :options="departments"
            optionValue="id"
            :disabled="user.organization == null"
            placeholder="Выберите значение"
            filter
          ></Dropdown>
        </div>
      </div>

      <div class="flex m-t-8">
        <div class="flex flex-col fl-1">
          <label class="small-label">Роль</label>
          <Dropdown
            v-model="user.role"
            :invalid="isInvalidForm && user.role == null"
            optionLabel="name"
            :options="getRoles()"
            optionValue="id"
            :disabled="user.department == null || user.organization == null"
            placeholder="Выберите значение"
            filter
          ></Dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, computed } from "vue";

import InputText from "primevue/inputtext";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";

import { useRoute, useRouter } from "vue-router";

import { generatePassword } from "@/services/utils/Password";
import CatalogDataSingleInput from "@/widgets/forms/CatalogDataSingleInput.vue";
import { useOrganizationsDictionaryStore } from "@/store/catalog_data/organizations";
import { useUsersStore } from "@/store/users/users";
import { Department } from "@/store/dictionaries/organizations";
import { isFilledUser } from "@/services/dictionaries/users";
import { rootGetters } from "@/main";

const emit = defineEmits(["setLoading"]);

const route = useRoute();
const router = useRouter();
const store = useUsersStore();
const orgDictionaryStore = useOrganizationsDictionaryStore();

const user = ref({
  fname: "",
  lname: "",
  organization: null as number | null,
  department: null as number | null,
  role: null as number | null,
  email: "",
  password: "",
});

const passwordClosed = ref(true);
const isInvalidForm = ref(false);
const departments = ref([] as Department[]);
const isUserSetted = computed(() => {
  return Boolean(route.params.id);
});

watch(
  () => user.value.organization,
  () => {
    setDepartments();
  }
);

watch(
  () => orgDictionaryStore.itemList,
  () => {
    setDepartments();
  }
);

const reload = () => {
  if (isUserSetted.value) {
    const userId = parseInt(route.params.id);
    store.getUser(userId).then((userClone) => {
      if (userClone) {
        user.value.fname = userClone.fname;
        user.value.lname = userClone.lname;
        user.value.email = userClone.email;
        user.value.role = userClone.role.id;
        user.value.department = userClone.role.department.id;
        user.value.organization = userClone.role.department.organization.id;
      }
    });
  }
  setDepartments();
};

onMounted(() => {
  reload();
});

const passClick = () => {
  passwordClosed.value = !passwordClosed.value;
};

const passGenerate = () => {
  user.value.password = generatePassword(16);
};

const setDepartments = () => {
  if (user.value.organization) {
    const foundItem = orgDictionaryStore.itemList.find(
      (item) => item.id === user.value.organization
    );
    if (foundItem) {
      departments.value = foundItem.departments;
      return;
    }
  }
  departments.value = [];
};

const getRoles = () => {
  if (user.value.department && departments.value) {
    const foundItem = departments.value.find(
      (item) => item.id === user.value.department
    );
    if (foundItem) return foundItem.roles;
  }
  return [];
};

const createUser = () => {
  if (!isFilledUser(user.value, !isUserSetted.value)) {
    isInvalidForm.value = true;
    rootGetters.toast.show(
      "warn",
      "Ошибка сохранения",
      `Проверьте, что все необходимые поля заполнены.`
    );
    return;
  }

  const userForCreation = {
    fname: user.value.fname,
    lname: user.value.lname,
    email: user.value.email,
    password: user.value.password,
    role_id: user.value.role,
  };

  emit("setLoading", true);
  if (isUserSetted.value)
    performAction(() =>
      store.updateUser(userForCreation, parseInt(route.params.id))
    );
  else performAction(() => store.createUser(userForCreation));
};

const performAction = (action: any) => {
  const handleSuccess = () => {
    router.push({ name: "users" });
    setTimeout(() => {
      store.activeFirst = false;
    }, 5000);
  };

  const handleError = (err: any) => {
    if (err.response && err.response.status == 409)
      rootGetters.toast.show("warn", "Ошибка", err.response.data.detail);
  };

  action()
    .then(handleSuccess)
    .catch(handleError)
    .finally(() => {
      emit("setLoading", false);
    });
};

defineExpose({
  createUser,
});
</script>

<style lang="scss" scoped>
.form-filter {
  max-width: 100%;
  width: 100%;
  padding-bottom: 40px;
}

.some-inputs {
  max-width: 100%;
}

.input-container {
  max-width: calc(50% - 10px);
  width: calc(50% - 10px);
}

.small-label {
  color: #a19cab;
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1.43rem;
  padding-bottom: 2px;
  padding-left: 4px;
}

.w-100 {
  width: 100%;
}

input.closed {
  -webkit-text-security: disc;
}
</style>
