<template>
  <DataTable
    :value="organizations"
    v-model:expandedRows="expandedRows"
    :class="[
      organizations && organizations.length == 0 ? 'empty-table' : '',
      isActiveFirst ? 'active-first' : '',
    ]"
    class="scroll-table-y main-table scrollbar-custom"
    stripedRows
    @sort="sortTable"
    removableSort
    scrollable
    :loading="isLoading"
    dataKey="id"
    columnResizeMode="expand"
  >
    <Column expander style="min-width: 52px; max-width: 52px" />
    <Column field="number" header="№" style="min-width: 44px">
      <template #body="slotProps">
        {{ rowsCount + slotProps.index + 1 }}
      </template>
    </Column>
    <Column
      field="name"
      header="Название организации"
      sortable
      style="min-width: 120px"
    >
      <template #body="slotProps">
        <RouterLink
          :to="{ name: 'organization', params: { id: slotProps.data.id } }"
        >
          {{ slotProps.data.name }}
        </RouterLink>
      </template>
    </Column>
    <Column field="description" header="Описание"></Column>
    <Column field="is_inhouse" header="Тип организации">
      <template #body="slotProps">
        <span v-if="slotProps.data.is_inhouse"> Внутренняя </span>
        <span v-else>Внешняя</span>
      </template>
    </Column>
    <Column field="tenant" header="Tenant"></Column>
    <Column
      field="cuser__lname"
      header="Пользователь"
      sortable
      style="max-width: 150px"
    >
      <template #body="slotProps">
        <span v-if="slotProps.data.cuser">
          {{ getUserName(slotProps.data.cuser) }}
        </span>
        <span v-else>-</span>
      </template>
    </Column>

    <Column
      field="ctime"
      header="Дата создания"
      sortable
      style="min-width: 153px"
    >
      <template #body="slotProps">
        {{ convertDateFromUTC(slotProps.data.ctime) }}
      </template>
    </Column>
    <Column
      field="mtime"
      header="Дата обновления"
      sortable
      style="min-width: 173px; max-width: 173px"
    >
      <template #body="slotProps">
        {{ convertDateFromUTC(slotProps.data.mtime) }}
      </template>
    </Column>

    <Column frozen alignFrozen="right">
      <template #body="slotProps">
        <div
          @click="(event) => toggle(slotProps.data.id, event)"
          aria-haspopup="true"
          aria-controls="overlay_menu"
          class="button-menu"
        >
          <span :data-segment="1" class="pi pi-ellipsis-v"></span>
        </div>
      </template>
    </Column>
    <template #expansion="slotProps">
      <div class="sub-table-container">
        <div class="sub-table-heading">Список департаментов</div>
        <DataTable
          :value="departmentsArray(slotProps.data.departments)"
          class="sub-table"
        >
          <Column
            field="departmentName"
            header="Наименование департамента"
          ></Column>
          <Column field="roleName" header="Должность"></Column>
          <Column
            field="ctime"
            header="Дата создания"
            sortable
            style="min-width: 153px"
          >
            <template #body="slotPropsRole">
              {{ convertDateFromUTC(slotPropsRole.data.ctime) }}
            </template>
          </Column>
          <Column
            field="mtime"
            header="Дата обновления"
            sortable
            style="min-width: 173px; max-width: 173px"
          >
            <template #body="slotPropsRole">
              {{ convertDateFromUTC(slotPropsRole.data.mtime) }}
            </template>
          </Column>
        </DataTable>
      </div>
    </template>
  </DataTable>
  <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" />
  <DeleteConfirmDialog
    deleted-name="организацию и все ее департаменты"
    :visible="confirmVisibleDelete"
    :blocked="blockedButtonDelete"
    @close="confirmVisibleDelete = false"
    @delete="deleteOrganization"
  />
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useRouter } from "vue-router";

import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Menu from "primevue/menu";
import DeleteConfirmDialog from "@/shared/dialogs/DeleteConfirmDialog.vue";

import { convertDateFromUTC } from "@/services/utils/DateParser";
import { getUserName } from "@/services/utils/Names";
import {
  useOrganizationsStore,
  Department,
} from "@/store/dictionaries/organizations";
import { rootGetters } from "@/main";

const props = defineProps<{
  organizations: any[];
  isActiveFirst: boolean;
  isLoading: boolean;
  rowsCount: number;
}>();
const emits = defineEmits(["sortTable", "updateTable"]);
const router = useRouter();
const store = useOrganizationsStore();

let modifiedId = ref(0);
const confirmVisibleDelete = ref(false);
const blockedButtonDelete = ref(false);
const menu = ref();
const expandedRows = ref({});
const items = ref([
  {
    label: "Редактировать",
    icon: "pi pi-pencil",
    archive: "no_archive",
    command: () => {
      router.push({ name: "organization", params: { id: modifiedId.value } });
    },
  },
  {
    label: "Удалить",
    icon: "pi pi-trash",
    archive: "both",
    command: () => {
      confirmVisibleDelete.value = true;
    },
  },
]);

const departmentsArray = (departments: Department[]) => {
  return departments
    .map((department) =>
      department.roles.map((role) => ({
        departmentName: department.name,
        roleName: role.name,
        ctime: role.ctime,
        mtime: role.mtime,
      }))
    )
    .flat();
};

const toggle = (elemId: number, event: any) => {
  modifiedId.value = elemId;
  menu.value.toggle(event);
};

const sortTable = (event: any) => {
  let orderField = "";
  if (event.sortField)
    orderField = event.sortOrder > 0 ? event.sortField : `-${event.sortField}`;
  emits("sortTable", orderField);
};

const deleteOrganization = () => {
  blockedButtonDelete.value = true;
  store
    .deleteOrganization(modifiedId.value)
    .finally(() => {
      emits("updateTable");
      blockedButtonDelete.value = false;
      confirmVisibleDelete.value = false;
    })
    .catch((err) => {
      if (err.response && err.response.status == 400)
        rootGetters.toast.show("warn", "Ошибка", err.response.data.detail);
    });
};
</script>
