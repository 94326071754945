<template>
  <InnerPageLayout>
    <HandlerAccessDenied :is-denied="isDenied">
      <TopUserPanel
        :isLoading="isLoading"
        :isButtonDisabled="isButtonDenied"
        @createUser="createUser"
      ></TopUserPanel>
      <div class="form-container">
        <UserCreateForm
          :isLoading="isLoading"
          @set-loading="setLoading"
          ref="createFormRef"
        ></UserCreateForm>
      </div>
    </HandlerAccessDenied>
  </InnerPageLayout>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useAuthStore } from "@/store/auth/login";

import InnerPageLayout from "@/widgets/layouts/InnerPageLayout.vue";
import TopUserPanel from "@/entities/dictionaries/users/panels/TopUserPanel.vue";
import UserCreateForm from "@/entities/dictionaries/users/forms/UserCreateForm.vue";
import HandlerAccessDenied from "@/shared/base/HandlerAccessDenied.vue";

const isLoading = ref(false);
const createFormRef = ref(null);
const authStore = useAuthStore();

const createUser = () => {
  if (createFormRef.value) createFormRef.value.createUser();
};

const setLoading = (value: boolean) => {
  isLoading.value = value;
};

const isDenied = computed(() => {
  return authStore.isDeniedForPermissions(
    ["system:read", "system:edit"],
    ["users:read", "users:edit"],
    ["dictionaries:read", "dictionaries:edit"]
  );
});

const isButtonDenied = computed(() => {
  return authStore.isDeniedForPermissions(
    ["system:edit"],
    ["users:edit"],
    ["dictionaries:edit"]
  );
});
</script>

<style lang="scss" scoped>
.form-container {
  position: relative;
  top: 77px;
  width: calc(100% - 316px);
}
</style>
