<template>
  <div class="auth-form">
    <h1>Вход в систему</h1>
    <div class="new-user">
      <div>Новый пользователь?</div>
      <a href="#">Создать учетную запись</a>
    </div>
    <div class="auth-form-components">
      <div class="flex flex-col input-labeled">
        <label for="username">E-mail</label>
        <InputText
          id="username"
          v-model="crendetails.username"
          aria-describedby="username-help"
          placeholder="Введите e-mail или номер телефона"
          :invalid="invalid"
        />
      </div>
      <div class="flex flex-col input-labeled">
        <label for="password">Пароль</label>
        <Password
          id="password"
          v-model="crendetails.password"
          placeholder="Введите пароль"
          :feedback="false"
          :invalid="invalid"
        />
      </div>
      <div class="bad-msg" v-if="invalid">Неправильный логин или пароль</div>
      <Button
        label="Войти"
        class="form-button"
        @click="login"
        :disabled="isProgress"
      />
    </div>
    <div class="after-info">
      <div class="forgot">
        <router-link :to="{ name: 'resetPassword' }"
          >Забыли пароль?</router-link
        >
      </div>
      <div class="policy">
        Нажимая на кнопку «Войти» вы принимаете
        <router-link :to="{ name: 'privacyPolicy' }"
          >условия пользовательского соглашения</router-link
        >
        и
        <router-link :to="{ name: 'personalDataPolicy' }"
          >разрешаете обработку персональных данных</router-link
        >
      </div>
    </div>
    <div class="fl-senter m-t-5" v-if="isProgress">
      <ProgressSpinner
        aria-label="Loading"
        style="width: 30px; height: 30px"
        strokeWidth="2"
        fill="var(--surface-ground)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

import { useRouter } from "vue-router";
import { useAuthStore } from "@/store/auth/login";

import InputText from "primevue/inputtext";
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import Password from "primevue/password";

const authStore = useAuthStore();
const router = useRouter();
const isProgress = ref(false);
const crendetails = ref({
  username: "",
  password: "",
});
const invalid = ref(false);

function login() {
  isProgress.value = true;
  localStorage.removeItem("access_token");
  invalid.value = false;

  authStore
    .login(crendetails.value)
    .then(() => {
      router.push(
        router.currentRoute.value.query.next?.toString() || "/segments"
      );
    })
    .catch(() => {
      invalid.value = true;
    })
    .finally(() => {
      isProgress.value = false;
    });
}
</script>

<style scoped lang="scss">
.auth-form {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 344px;
}

h1 {
  font-size: 2em;
  font-weight: 600;
  line-height: 142%;
  margin-bottom: 8px;
}

.new-user {
  display: flex;
  font-size: 0.86em;
  font-weight: 600;
  line-height: 150%;

  div {
    margin-right: 10px;
  }

  a {
    color: $main-link-color;
  }
}

label {
  font-weight: 500;
  font-size: 1em;
  line-height: 160%;
  color: #bcbac2;
  margin-bottom: 4px;
  padding-left: 5px;
}

.input-labeled {
  margin-top: 12px;
  width: 100%;
}

:deep(.p-inputtext.p-component) {
  width: 100%;
  font-size: 14px;
  color: $main-font-color;
  padding: 8px 12px;
}

.form-button {
  margin-top: 19px;
  width: 100%;
}

.bad-msg {
  font-size: 0.9em;
  font-weight: 400;
  line-height: 130%;
  margin-top: 10px;
  color: red;
}

.after-info {
  .forgot {
    font-weight: 500;
    margin-top: 6px;
    font-size: 0.86em;

    a {
      color: $main-link-color;
    }
  }

  .policy {
    font-size: 0.71em;
    font-weight: 400;
    line-height: 170%;
    margin-top: 10px;

    a {
      color: #6366f1;
    }
  }
}

.m-t-5 {
  margin-top: 5px;
}

.fl-senter {
  display: flex;
  justify-content: center;
}
</style>
