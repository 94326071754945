<template>
  <ListFilterForm
    :is-filter-enabled="props.isFilterEnabled"
    :initial-filter="initialFilter"
    @toggle-filter="emits('toggleFilter')"
    @set-filter="setParentFilter"
  >
    <template #fields="slotProps">
      <div class="flex flex-col">
        <label for="segmentMame" class="small-label"
          >Наименование кабинета</label
        >
        <InputText
          id="segmentMame"
          placeholder="Введите название"
          v-model="slotProps.filter.name"
          aria-describedby="username-help"
        />
      </div>

      <div class="flex flex-col m-t-8">
        <label for="state" class="small-label">Площадка</label>
        <CatalogDataBaseInput
          :not-search="true"
          :useIdOnly="true"
          selectType="source"
          :isSegmentSetted="false"
          :modelValue="slotProps.filter.source_slug"
          @update:modelValue="
            ($event) => (slotProps.filter.source_slug = $event)
          "
        ></CatalogDataBaseInput>
      </div>

      <div class="flex m-t-18 m-b-8 fl-jc-sb">
        <label for="updated">Статистика собирается в SSP</label>
        <InputSwitch
          v-model="slotProps.filter.is_statistics_required"
          inputId="updated"
        />
      </div>
    </template>
  </ListFilterForm>
</template>

<script setup lang="ts">
import InputText from "primevue/inputtext";
import InputSwitch from "primevue/inputswitch";
import CatalogDataBaseInput from "@/widgets/forms/CatalogDataBaseInput.vue";
import ListFilterForm from "@/widgets/forms/ListFilterForm.vue";

const props = defineProps<{
  isFilterEnabled: boolean;
}>();
const emits = defineEmits(["toggleFilter", "setFilter"]);

const initialFilter = {
  name: "",
  source_slug: [] as string[],
  is_statistics_required: true,
};

const setParentFilter = (filter: any, isSetted: boolean) => {
  emits("setFilter", filter, isSetted);
};
</script>
