<template>
  <ListFilterForm
    :is-filter-enabled="props.isFilterEnabled"
    :initial-filter="initialFilter"
    @toggle-filter="emits('toggleFilter')"
    @set-filter="setParentFilter"
  >
    <template #fields="slotProps">
      <div class="flex flex-col">
        <label for="segmentMame" class="small-label"
          >Наименование площадки</label
        >
        <InputText
          id="segmentMame"
          placeholder="Введите название"
          v-model="slotProps.filter.name"
          aria-describedby="username-help"
        />
      </div>

      <div class="flex flex-col m-t-8">
        <label class="small-label">Паттерн</label>
        <InputText
          placeholder="Введите название"
          v-model="slotProps.filter.pattern"
          aria-describedby="username-help"
        />
      </div>
      <div class="flex flex-col m-t-8">
        <label for="state" class="small-label">Автор</label>
        <CatalogDataBaseInput
          :useIdOnly="true"
          selectType="user"
          :isSegmentSetted="false"
          :modelValue="slotProps.filter.user"
          @update:modelValue="($event) => (slotProps.filter.user = $event)"
        ></CatalogDataBaseInput>
      </div>
      <div class="flex flex-col m-t-8">
        <label for="date" class="small-label">Дата создания</label>
        <Calendar
          id="date"
          panelClass="not-extended"
          placeholder="Выберите значение"
          v-model="slotProps.filter.cdate"
          aria-describedby="date-help"
          showIcon
          iconDisplay="input"
          inputId="icondisplay"
        />
      </div>
      <div class="flex flex-col m-t-8">
        <label for="date" class="small-label">Дата редактирования</label>
        <Calendar
          id="date"
          panelClass="not-extended"
          placeholder="Выберите значение"
          v-model="slotProps.filter.mdate"
          aria-describedby="date-help"
          showIcon
          iconDisplay="input"
          inputId="icondisplay"
        />
      </div>
      <div class="flex flex-col m-t-8">
        <label class="small-label">Платформа</label>
        <div class="fl m-b-8 m-t-4">
          <RadioButton
            v-model="slotProps.filter.is_platform"
            inputId="yes"
            name="isPlatform"
            value="1"
          />
          <label for="yes" class="p-l-4">Да</label>
        </div>
        <div class="fl">
          <RadioButton
            v-model="slotProps.filter.is_platform"
            inputId="no"
            name="isPlatform"
            value="0"
          />
          <label for="no" class="p-l-4">Нет</label>
        </div>
      </div>
    </template>
  </ListFilterForm>
</template>

<script setup lang="ts">
import InputText from "primevue/inputtext";
import CatalogDataBaseInput from "@/widgets/forms/CatalogDataBaseInput.vue";
import ListFilterForm from "@/widgets/forms/ListFilterForm.vue";
import Calendar from "primevue/calendar";
import RadioButton from "primevue/radiobutton";

const props = defineProps<{
  isFilterEnabled: boolean;
}>();
const emits = defineEmits(["toggleFilter", "setFilter"]);

const initialFilter = {
  name: "",
  pattern: "",
  cdate: null,
  mdate: null,
  user: [] as number[],
  is_platform: "",
};

const setParentFilter = (filter: any, isSetted: boolean) => {
  emits("setFilter", filter, isSetted);
};
</script>
