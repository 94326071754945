<template>
  <div class="form-filter">
    <div class="form-block">
      <div class="flex flex-col fl-1 m-b-8">
        <label for="name" class="small-label"
          >Наименование рекламной площадки</label
        >
        <SceletonInput :is-loading="isLoadingData">
          <InputText
            id="name"
            placeholder="Введите значение"
            v-model="platform.name"
            :invalid="isInvalidForm && platform.name == ''"
          />
        </SceletonInput>
      </div>

      <div class="flex flex-col fl-1 m-b-8">
        <label for="name" class="small-label">Slug</label>
        <SceletonInput :is-loading="isLoadingData">
          <InputText
            :disabled="isPlatformSetted"
            id="name"
            placeholder="Введите значение"
            v-model="platform.slug"
            :invalid="isInvalidForm && platform.slug == ''"
          />
        </SceletonInput>
      </div>

      <div class="flex flex-col fl-1 m-b-8">
        <div class="m-b-12">Рекламная площадка является платформой:</div>
        <SceletonInput :is-loading="isLoadingData">
          <div class="fl m-b-8">
            <RadioButton
              v-model="platform.is_platform"
              inputId="yes"
              name="isPlatform"
              value="1"
            />
            <label for="yes" class="p-l-4">Да</label>
          </div>
          <div class="fl">
            <RadioButton
              v-model="platform.is_platform"
              inputId="no"
              name="isPlatform"
              value="0"
            />
            <label for="no" class="p-l-4">Нет</label>
          </div>
        </SceletonInput>
      </div>

      <label for="name" class="small-label">Паттерны</label>
      <div
        class="flex flex-col fl-1 m-b-8 role-container"
        v-for="(pattern, idxr) in platform.patterns"
        :key="`pattern-${idxr}`"
      >
        <div class="flex flex-center">
          <InputText
            id="name"
            class="fl-1"
            placeholder="Введите значение"
            v-model="pattern.name"
            :invalid="isInvalidForm && pattern.name == ''"
          />
          <div
            class="group-button-container role flex"
            :class="[idxr != platform.patterns.length - 1 ? 'hidden' : '']"
          >
            <div
              v-if="platform.patterns.length > 1"
              class="delete-button flex flex-center m-l-8"
              @click="deletePattern(idxr)"
            >
              <span class="pi pi-trash f-s-12"></span>
            </div>
            <div class="add-button flex flex-center" @click="addPattern(idxr)">
              <span class="pi pi-plus"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { usePlatformsStore } from "@/store/dictionaries/platforms";

import InputText from "primevue/inputtext";
import { arePlatformFieldsValid } from "@/services/dictionaries/platforms";
import SceletonInput from "@/shared/forms/SceletonInput.vue";
import RadioButton from "primevue/radiobutton";

import { rootGetters } from "@/main";

const emit = defineEmits(["setLoading"]);
const route = useRoute();
const router = useRouter();
const store = usePlatformsStore();

const platform = ref({
  name: "",
  is_platform: "1",
  slug: "",
  patterns: [{ name: "" }],
});

const isInvalidForm = ref(false);
const isPlatformSetted = computed(() => {
  return Boolean(route.params.id);
});
const isLoadingData = ref(false);

const reload = () => {
  if (isPlatformSetted.value) {
    const platformSlug = route.params.id;
    isLoadingData.value = true;
    store
      .getPlatform(platformSlug)
      .then((platformClone) => {
        if (platformClone) {
          platform.value.name = platformClone.name;
          platform.value.slug = platformClone.slug;
          platform.value.is_platform = platformClone.is_platform ? "1" : "0";
          platform.value.patterns = platformClone.patterns.map((e: string) => {
            return {
              name: e,
            };
          });
        }
      })
      .finally(() => {
        isLoadingData.value = false;
      });
  }
};

onMounted(() => {
  reload();
});

const createPlatform = () => {
  const patternNames: string[] = platform.value.patterns.map(
    (pattern) => pattern.name
  );

  const platformToServer = {
    slug: platform.value.slug,
    name: platform.value.name,
    patterns: patternNames,
    is_platform: platform.value.is_platform == "1" ? true : false,
  };

  if (!arePlatformFieldsValid(platformToServer)) {
    isInvalidForm.value = true;
    rootGetters.toast.show(
      "warn",
      "Ошибка сохранения",
      `Проверьте, что все необходимые поля заполнены.`
    );
    return;
  }
  emit("setLoading", true);
  if (!isPlatformSetted.value) {
    performPlatformAction(() => store.createPlatform(platformToServer));
  } else {
    performPlatformAction(() =>
      store.updatePlatform(platformToServer, route.params.id)
    );
  }
};

const performPlatformAction = (action: any) => {
  const handleSuccess = () => {
    router.push({ name: "platforms" });
    setTimeout(() => {
      store.activeFirst = false;
    }, 5000);
  };

  action()
    .then(handleSuccess)
    .catch((err: any) => {
      if (err.response && err.response.status == 409)
        rootGetters.toast.show("warn", "Ошибка", err.response.data.detail);
    })
    .finally(() => {
      emit("setLoading", false);
    });
};

const addPattern = (idx: number) => {
  platform.value.patterns.splice(idx + 1, 0, { name: "" });
};

const deletePattern = (idx: number) => {
  platform.value.patterns.splice(idx, 1);
};

defineExpose({
  createPlatform,
});
</script>

<style lang="scss" scoped>
.form-filter {
  max-width: 100%;
  width: 100%;
  padding-bottom: 40px;
  min-width: 500px;
}

.form-block-title {
  margin-bottom: 8px;
}

.role-container:hover .group-button-container.hidden.role {
  display: flex;
}

.small-label {
  color: #a19cab;
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1.43rem;
  padding-bottom: 4px;
  padding-left: 4px;
}

.group-button-container.role.hidden {
  display: none;
}

.delete-button {
  border-radius: 5px;
  background: #f7f7fd;
  width: 32px;
  height: 32px;
  padding-left: 9px;
  cursor: pointer;

  &:hover {
    background-color: #fafaff;
  }
}

.add-button {
  background-color: #dbdaf9;
  border-radius: 5px;
  padding: 3px 9px;
  cursor: pointer;
  height: 32px;
  margin-left: 12px;

  &:hover {
    background-color: #d4d3f8;

    outline-color: transparent;
  }
}

.f-s-12 {
  font-size: 12px;
}

.sub-block {
  padding: 12px;
  background-color: #f7f7fd;
  border: 1px solid #d0d0d6;
  border-radius: 6px;
}
</style>
