import { PlatformsParams, BasePlatform } from "@/store/dictionaries/platforms";
import { formatDateToYYYYMMDD } from "../utils/DateParser";

export function arePlatformFieldsValid(platform: BasePlatform): boolean {
    return !(!platform.name || !platform.slug || !platform.patterns.every(str => str.length > 0))
}

export function setPlatformsParamsByFilter(params: PlatformsParams, settings: TableSettings) {
    if (settings.orderField) {
        params.order_by = settings.orderField;
    } else {
        params.order_by = '-mtime';
    }

    if (settings.commonFilter) {
        params.name__ilike = settings.commonFilter;
    }

    if (Object.keys(settings.filter).length > 0) {
        if (settings.filter.name) {
            params.name__ilike = settings.filter.name;
        }

        if (settings.filter.is_platform.length > 0) {
            params.is_platform = settings.filter.is_platform == "1" ? true : false;
        }

        if (settings.filter.user.length > 0) {
            params.cuser__id__in = settings.filter.user.join(",");
        }

        if (settings.filter.cdate)
            params.cdate = formatDateToYYYYMMDD(settings.filter.cdate);

        if (settings.filter.mdate)
            params.mdate = formatDateToYYYYMMDD(settings.filter.mdate);

        if (settings.filter.pattern.length > 0) {
            params.patterns_ilike = settings.filter.pattern;
        }
    }
}

interface TableSettings {
    first: number;
    orderField: string;
    commonFilter: string;
    filter: FilterForm;
}

export interface FilterForm {
    name: string;
    is_platform: string;
    cdate: Date | null;
    mdate: Date | null;
    user: number[];
    pattern: string;
}