import { OrganizationFromServer, DepartmentFromServer } from "@/store/dictionaries/organizations";

export function excludeDepartmentsAndRoles(org: OrganizationFromServer, excludeDepartments: number[], excludeRoles: number[]): OrganizationFromServer {
    const filteredDepartments = excludeDepartments.length
        ? org.departments.filter(dept => excludeDepartments.includes(dept.id))
        : org.departments;
    const filteredDepartmentsWithRoles = filteredDepartments.map(dept => ({
        ...dept,
        roles: excludeRoles.length
            ? dept.roles.filter(role => excludeRoles.includes(role.id))
            : dept.roles
    }));

    return {
        ...org,
        departments: filteredDepartmentsWithRoles
    };
}

export function filterByStr(org: OrganizationFromServer, str: string): OrganizationFromServer {
    const strSearch = str.toLowerCase()
    const filteredDepartments = org.departments
        .map(department => {
            const filteredRoles = department.roles.filter(role =>
                role.name.toLowerCase().includes(strSearch)
            );

            if (department.name.toLowerCase().includes(strSearch)) {
                return {
                    ...department,
                    roles: department.roles
                };
            }

            if (filteredRoles.length > 0) {
                return {
                    ...department,
                    roles: filteredRoles
                };
            }

            return null;
        })
        .filter(department => department !== null);

    return {
        ...org,
        departments: filteredDepartments as DepartmentFromServer[]
    };
}