export function getUserName(user: User) {
    const fname = user.fname.length > 0 ? user.fname[0] + '.' : ''
    return `${user.lname} ${fname}`
}

export function getUserFullName(user: User) {
    return `${user.lname} ${user.fname}`
}

interface User {
    id: number
    email: string
    fname: string
    lname: string
}