export function extractTopLevelBracketExpressions(str: string) {
    const results = [];
    const stack = [];
    let startIdx = -1;

    // Пройтись по каждому символу строки
    for (let i = 0; i < str.length; i++) {
        if (str[i] === '(') {
            // Если это первая открывающая скобка, сохранить её индекс
            if (stack.length === 0) {
                startIdx = i;
            }
            // Добавить скобку в стек
            stack.push(str[i]);
        } else if (str[i] === ')') {
            // Снять скобку со стека
            stack.pop();
            // Если это была последняя скобка, вырезать выражение и добавить в результат
            if (stack.length === 0) {
                results.push(str.substring(startIdx + 1, i));
            }
        }
    }

    return results;
}

export function cleanUnderscores(str: string): string {
    // 1. Заменить более одного последовательного подчеркивания на одно
    str = str.replace(/_+/g, '_');

    // 2. Убрать подчеркивания по краям строки
    str = str.replace(/^_+|_+$/g, '');

    return str;
}

export function formatNumber(num: number) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function getSubstringBetweenDots(str: string): string | null {
    const firstDotIndex = str.indexOf('.');
    const lastDotIndex = str.lastIndexOf('.');

    if (firstDotIndex !== -1 && lastDotIndex !== -1 && firstDotIndex !== lastDotIndex) {
        return str.substring(firstDotIndex + 1, lastDotIndex);
    } else {
        return null;
    }
}

export function decodeBase64(encodedString: string) {
    try {
        const decodedString = atob(encodedString);
        return decodedString;
    } catch (e) {
        console.error("Ошибка декодирования Base64:", e);
        return null;
    }
}