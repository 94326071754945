<template>
  <InnerPageLayout>
    <HandlerAccessDenied :is-denied="isDenied">
      <TopCampaignPanel
        :is-button-disabled="isDeniedSave"
        :isLoading="isLoading"
        @create-campaign="createCampaign"
      ></TopCampaignPanel>
      <div class="form-container">
        <CampaignCreateForm
          :isLoading="isLoading"
          @set-loading="setLoading"
          ref="createFormRef"
        ></CampaignCreateForm>
      </div>
    </HandlerAccessDenied>
  </InnerPageLayout>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useAuthStore } from "@/store/auth/login";

import InnerPageLayout from "@/widgets/layouts/InnerPageLayout.vue";
import TopCampaignPanel from "@/entities/campaigns/panels/TopCampaignPanel.vue";
import CampaignCreateForm from "@/entities/campaigns/forms/CampaignCreateForm.vue";
import HandlerAccessDenied from "@/shared/base/HandlerAccessDenied.vue";

const isLoading = ref(false);
const createFormRef = ref(null);
const authStore = useAuthStore();

const isDenied = computed(() => {
  return authStore.isDeniedForPermissions([
    "campaign:read",
    "campaign:edit",
    "system:read",
    "system:edit",
  ]);
});

const isDeniedSave = computed(() => {
  return authStore.isDeniedForPermissions(["campaign:edit", "system:edit"]);
});

const createCampaign = () => {
  if (createFormRef.value) createFormRef.value.createCampaign();
};

const setLoading = (value: boolean) => {
  isLoading.value = value;
};
</script>

<style lang="scss" scoped>
.form-container {
  position: relative;
  top: 77px;
  width: calc(100% - 316px);
}
</style>
