<template>
  <div class="form-filter">
    <div class="form-block">
      <div class="form-block-title bold">Данные для авторизации</div>
      <div class="flex some-inputs m-t-8">
        <div class="flex flex-col m-r-20 input-container">
          <label class="small-label">Почта для входа в систему</label>
          <Skeleton height="2rem" class="mb-2" v-if="isLoading"></Skeleton>
          <InputText
            v-if="!isLoading"
            placeholder="Введите значение"
            v-model="user.email"
            :disabled="true"
          />
        </div>
        <div class="flex flex-col input-container">
          <label class="small-label">Пароль</label>
          <Skeleton height="2rem" class="mb-2" v-if="isLoading"></Skeleton>
          <div class="flex" v-if="!isLoading">
            <InputText
              class="m-r-8 fl-1"
              placeholder="Введите значение"
              v-model="user.password"
              :disabled="true"
            />
            <Button
              icon="pi pi-pencil"
              class="purple-button"
              aria-label="Изменить пароль"
              @click="router.push({ name: 'changePassword' })"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="form-block">
      <div class="form-block-title bold">Основные данные</div>

      <div class="flex some-inputs m-t-8">
        <div class="flex flex-col m-r-20 input-container">
          <label class="small-label">Фамилия</label>
          <Skeleton height="2rem" class="mb-2" v-if="isLoading"></Skeleton>
          <InputText
            v-else
            placeholder="Введите значение"
            v-model="user.lname"
          />
        </div>
        <div class="flex flex-col input-container">
          <label class="small-label">Имя</label>
          <Skeleton height="2rem" class="mb-2" v-if="isLoading"></Skeleton>
          <InputText
            v-else
            placeholder="Введите значение"
            v-model="user.fname"
          />
        </div>
      </div>

      <div class="flex some-inputs m-t-8">
        <div class="flex flex-col m-r-20 input-container">
          <label class="small-label">Организация</label>
          <Skeleton height="2rem" class="mb-2" v-if="isLoading"></Skeleton>
          <InputText
            v-else
            placeholder="Введите значение"
            v-model="user.organization"
            :disabled="true"
          />
        </div>
        <div class="flex flex-col input-container">
          <label class="small-label">Департамент</label>
          <Skeleton height="2rem" class="mb-2" v-if="isLoading"></Skeleton>
          <InputText
            v-else
            placeholder="Введите значение"
            v-model="user.department"
            :disabled="true"
          />
        </div>
      </div>

      <div class="flex m-t-8">
        <div class="flex flex-col fl-1">
          <label class="small-label">Роль</label>
          <Skeleton height="2rem" class="mb-2" v-if="isLoading"></Skeleton>
          <InputText
            v-else
            placeholder="Введите значение"
            v-model="user.role"
            :disabled="true"
          />
        </div>
      </div>
    </div>

    <div class="flex fl-1 m-t-20 fl-jc-sb">
      <div class="flex" :class="[!saveVisible ? 'none' : '']">
        <Button label="Сохранить" @click="changeFI" class="m-r-20 f-12" />
        <Button
          label="Сбросить изменения"
          @click="cancel"
          class="black-12"
          severity="secondary"
        />
      </div>
      <div class="flex fl-jc-end" :class="[!saveVisible ? 'fl-1' : '']">
        <Button
          label="Выйти из аккаунта"
          class="purple-button"
          icon="pi pi-sign-out"
          @click="logout"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from "vue";

import { useAuthStore } from "@/store/auth/login";
import { useUsersStore } from "@/store/users/users";
import { useRouter } from "vue-router";

import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Skeleton from "primevue/skeleton";

const user = ref({
  fname: "",
  lname: "",
  organization: "",
  department: "",
  role: "",
  email: "",
  password: "*********",
});

const nameCopy = ref({
  fname: "",
  lname: "",
});

const isLoading = ref(false);

const saveVisible = ref(false);

const authStore = useAuthStore();
const usersStore = useUsersStore();
const router = useRouter();

const setVisible = () => {
  if (
    (user.value.fname != nameCopy.value.fname ||
      user.value.lname != nameCopy.value.lname) &&
    user.value.fname != "" &&
    user.value.lname != ""
  )
    saveVisible.value = true;
  else saveVisible.value = false;
};

watch(
  () => user.value.fname,
  () => {
    setVisible();
  },
  { immediate: true }
);

watch(
  () => user.value.lname,
  () => {
    setVisible();
  },
  { immediate: true }
);

const reload = () => {
  isLoading.value = true;
  usersStore
    .getCurrentUser()
    .then((userClone) => {
      user.value.fname = userClone.fname;
      user.value.email = userClone.email;
      user.value.lname = userClone.lname;

      nameCopy.value.fname = userClone.fname;
      nameCopy.value.lname = userClone.lname;

      if (userClone.role) {
        user.value.role = userClone.role.name;
        user.value.department = userClone.role.department.name;
        user.value.organization = userClone.role.department.organization.name;
      }
    })
    .finally(() => {
      isLoading.value = false;
    });
};

onMounted(() => {
  reload();
});

function logout() {
  authStore.logout().then(() => {
    location.reload();
  });
}

const changeFI = () => {
  usersStore
    .editCurrentUser({ lname: user.value.lname, fname: user.value.fname })
    .then(() => {
      nameCopy.value.fname = user.value.fname;
      nameCopy.value.lname = user.value.lname;
      saveVisible.value = false;
    });
};

const cancel = () => {
  user.value.fname = nameCopy.value.fname;
  user.value.lname = nameCopy.value.lname;
};
</script>

<style lang="scss" scoped>
.form-filter {
  max-width: 100%;
  width: 100%;
  padding-bottom: 40px;
  //   min-width: 780px;
}

.form-block-title.bold {
  font-weight: 600;
}

.some-inputs {
  max-width: 100%;
}

.input-container {
  max-width: calc(50% - 10px);
  width: calc(50% - 10px);
}

.small-label {
  color: #a19cab;
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1.43rem;
  padding-bottom: 2px;
  padding-left: 4px;
}

.black-12 {
  color: $main-font-color;
  font-size: 12px;
  background-color: #eaeaf0;
}

.f-12 {
  font-size: 12px;
}
</style>
