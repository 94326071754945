<template>
  <ListItemsWithTableLayout>
    <template #body>
      <HandlerAccessDenied :is-denied="isDenied">
        <SettingsFilterForm
          :isFilterEnabled="isFilterEnabled"
          @toggle-filter="isFilterEnabled = !isFilterEnabled"
          @set-filter="setFilter"
        />
        <TopSettingsPanel
          :isFilterEnabled="isFilterEnabled"
          :isFilterSetted="isFilterSetted"
          @set-common-filter="setCommonFilter"
          @toggle-filter="isFilterEnabled = !isFilterEnabled"
          search-placeholder="Поиск"
        ></TopSettingsPanel>
        <div
          id="table-container"
          class="table-container flex flex-col"
          :class="[
            choosenOrganization && choosenOrganization.departments.length == 0
              ? 'empty'
              : 'filled',
          ]"
        >
          <PermissionsTable
            :organization="choosenOrganization"
            :isLoading="isLoading"
            @set-loading="setLoading"
          >
          </PermissionsTable>
          <EmptyTableMessage
            v-if="
              choosenOrganization &&
              choosenOrganization.departments.length == 0 &&
              !isLoading
            "
          >
            <template #text>
              Создайте первую рекламную кампанию. <br />
              Для этого кликните на кнопку "Создать РК".
            </template>
          </EmptyTableMessage>
        </div>
      </HandlerAccessDenied>
    </template>
  </ListItemsWithTableLayout>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";

import ListItemsWithTableLayout from "@/widgets/layouts/ListItemsWithTableLayout.vue";
import TopSettingsPanel from "@/entities/settings/panels/TopSettingsPanel.vue";
import EmptyTableMessage from "@/entities/segments/EmptyTableMessage.vue";
import PermissionsTable from "@/entities/settings/PermissionsTable.vue";
import SettingsFilterForm from "@/entities/settings/forms/SettingsFilterForm.vue";
import HandlerAccessDenied from "@/shared/base/HandlerAccessDenied.vue";

import {
  useOrganizationsStore,
  OrganizationFromServer,
} from "@/store/dictionaries/organizations";
import { useAuthStore } from "@/store/auth/login";

import {
  excludeDepartmentsAndRoles,
  filterByStr,
} from "@/services/permissions/utils";

const organizationsStore = useOrganizationsStore();
const authStore = useAuthStore();
const isLoading = ref(false);
const isFilterEnabled = ref(false);
const isFilterSetted = ref(false);

const choosenOrganization = ref<OrganizationFromServer | null>(null);
const choosenOrganizationWithoutFilter = ref<OrganizationFromServer | null>(
  null
);

onMounted(() => {
  if (isDenied.value) return;
  setFilter(
    {
      organization: 1,
      department: [],
      role: [],
    },
    true
  );
});

const isDenied = computed(() => {
  return authStore.isDeniedForPermissions([
    "settings:read",
    "settings:edit",
    "system:read",
    "system:edit",
  ]);
});

const loadData = (orgId: number) => {
  isLoading.value = true;

  return organizationsStore
    .getOrganizationBase(orgId)
    .then((organization) => {
      choosenOrganization.value = organization;
      choosenOrganizationWithoutFilter.value = JSON.parse(
        JSON.stringify(organization)
      );
    })
    .finally(() => {
      isLoading.value = false;
    });
};

const setCommonFilter = (filter: string) => {
  if (filter && choosenOrganization.value) {
    choosenOrganization.value = filterByStr(choosenOrganization.value, filter);
  } else {
    choosenOrganization.value = JSON.parse(
      JSON.stringify(choosenOrganizationWithoutFilter.value)
    );
  }
};

const setLoading = (loading: boolean) => {
  isLoading.value = loading;
};

const setFilter = (filter: any, isSetted: boolean) => {
  loadData(filter.organization).then(() => {
    if (choosenOrganization.value)
      choosenOrganization.value = excludeDepartmentsAndRoles(
        choosenOrganization.value,
        filter.department,
        filter.role
      );
    choosenOrganizationWithoutFilter.value = JSON.parse(
      JSON.stringify(choosenOrganization.value)
    );

    isFilterSetted.value = isSetted;
  });
};
</script>
