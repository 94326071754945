import { createStoreConfig } from "../catalog_data/common";
import { CatalogDataParams } from "../catalog_data/catalog_data";

const endpoints = {
    getItems: { method: "get", url: "/users/base" },
};

export const useUsersDictionaryStore = createStoreConfig<User>(endpoints, "users_dictionarie", {
    order_by: "lname,fname",
    in: "id__in",
    pk: "id",
    ilike: "search",
    nameKeys: ["fname", "lname"],
    sortOptions: {
        primarySortField: "lname",
        secondarySortFields: [{ field: "fname" }],
    },
});

export interface User {
    id: number;
    email: string;
    fname: string;
    lname: string;
    name?: string;
}

export interface UserDataParams extends CatalogDataParams {
    search?: string;
    id__in?: string;
}
