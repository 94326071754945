<template>
  <DataTable
    :value="campaigns"
    v-model:expandedRows="expandedRows"
    :class="[
      campaigns && campaigns.length == 0 ? 'empty-table' : '',
      isActiveFirst ? 'active-first' : '',
    ]"
    class="scroll-table-y main-table scrollbar-custom"
    stripedRows
    @sort="sortTable"
    removableSort
    scrollable
    :loading="isLoading"
    dataKey="id"
    columnResizeMode="expand"
  >
    <Column expander style="min-width: 52px; max-width: 52px" />
    <Column field="number" header="№" style="min-width: 44px">
      <template #body="slotProps">
        {{ rowsCount + slotProps.index + 1 }}
      </template>
    </Column>
    <Column field="name" header="Название РК" sortable style="min-width: 120px">
      <template #body="slotProps">
        <RouterLink
          :to="{ name: 'campaign', params: { id: slotProps.data.id } }"
        >
          {{ slotProps.data.name }}
        </RouterLink>
      </template>
    </Column>
    <Column field="period" header="Период" style="max-width: 153px">
      <template #body="slotProps">
        {{ convertDateFromISOWithShortYear(slotProps.data.start_date) }} -
        {{ convertDateFromISOWithShortYear(slotProps.data.end_date) }}
      </template>
    </Column>

    <Column field="brand" header="Бренд">
      <template #body="slotProps">
        <span class="table-chip" v-if="slotProps.data.brand">
          {{ slotProps.data.brand.brand_name }}
        </span>
        <span v-else>-</span>
      </template>
    </Column>

    <Column
      field="cuser__lname"
      header="Пользователь"
      sortable
      style="max-width: 150px"
    >
      <template #body="slotProps">
        <span v-if="slotProps.data.cuser">
          {{ getUserName(slotProps.data.cuser) }}
        </span>
        <span v-else>-</span>
      </template>
    </Column>

    <Column field="state" header="Статус" style="max-width: 150px">
      <template #body="slotProps">
        <svg
          :class="slotProps.data.state"
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
        >
          <circle cx="4" cy="4" r="4" fill="#F3E7AB" />
        </svg>
        {{
          campaignsDictionariesStore.statesMap.get(slotProps.data.state)?.name
        }}
      </template>
    </Column>
    <Column
      field="ctime"
      header="Дата создания"
      sortable
      style="min-width: 153px"
    >
      <template #body="slotProps">
        {{ convertDateFromUTC(slotProps.data.ctime) }}
      </template>
    </Column>
    <Column
      field="mtime"
      header="Дата обновления"
      sortable
      style="min-width: 173px; max-width: 173px"
    >
      <template #body="slotProps">
        {{ convertDateFromUTC(slotProps.data.mtime) }}
      </template>
    </Column>

    <Column frozen alignFrozen="right">
      <template #body="slotProps">
        <div
          @click="(event) => toggle(slotProps.data.id, event)"
          aria-haspopup="true"
          aria-controls="overlay_menu"
          class="button-menu"
        >
          <span :data-segment="1" class="pi pi-ellipsis-v"></span>
        </div>
      </template>
    </Column>
    <template #expansion="slotProps">
      <div class="sub-table-container">
        <div class="sub-table-heading">Список сегментов</div>
        <DataTable :value="slotProps.data.campaign_segments" class="sub-table">
          <Column
            field="sname"
            header="Название сегмента"
            style="min-width: 164px"
          >
            <template #body="slotPropsSegment">
              <RouterLink
                :to="{
                  name: 'segment',
                  params: { id: slotPropsSegment.data.segment.id },
                }"
              >
                {{ slotPropsSegment.data.segment.name }}
              </RouterLink>
            </template>
          </Column>

          <Column
            field="rich"
            header="Ожидаемый охват"
            style="min-width: 130px"
          >
            <template #body="slotPropsSegment">
              <span v-if="slotPropsSegment.data.segment.rich">
                {{ formatNumber(slotPropsSegment.data.segment.rich.rich) }}
              </span>
            </template>
          </Column>
          <Column
            field="platform"
            header="Рекламная площадка"
            style="min-width: 175px"
          >
            <template #body="slotPropsSegment">
              <PlatformChip
                :icon-class="slotPropsSegment.data.ads_cabinet.source_slug"
              >
                {{
                  slotPropsSegment.data.ads_cabinet.source.name
                }}</PlatformChip
              >
            </template>
          </Column>

          <Column field="state" header="Статус">
            <template #body="slotPropsSegment">
              <svg
                :class="slotPropsSegment.data.state"
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
              >
                <circle cx="4" cy="4" r="4" fill="#F3E7AB" />
              </svg>
              {{
                campaignsDictionariesStore.statesSegmentsMap.get(
                  slotPropsSegment.data.state
                )?.name
              }}
            </template>
          </Column>
          <Column
            field="ctime"
            header="Дата создания"
            sortable
            style="max-width: 153px"
          >
            <template #body="slotPropsSegment">
              {{ convertDateFromUTC(slotPropsSegment.data.ctime) }}
            </template>
          </Column>
          <Column
            field="mtime"
            header="Дата обновления"
            sortable
            style="max-width: 173px"
          >
            <template #body="slotPropsSegment">
              {{ convertDateFromUTC(slotPropsSegment.data.mtime) }}
            </template>
          </Column>
        </DataTable>
      </div>
    </template>
  </DataTable>
  <Menu ref="menu" id="overlay_menu" :model="filteredItems" :popup="true" />
  <DeleteConfirmDialog
    deleted-name="кампанию"
    :visible="confirmVisible.delete"
    :blocked="blockedButton.delete"
    @close="confirmVisible.delete = false"
    @delete="deleteCampaign"
  />
  <ArchiveConfirmDialog
    archived-name="рекламную кампанию"
    :visible="confirmVisible.archive"
    :blocked="blockedButton.archive"
    @close="confirmVisible.archive = false"
    @archive="archiveCampaign"
  />
</template>

<script setup lang="ts">
import { ref, computed } from "vue";

import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Menu from "primevue/menu";
import DeleteConfirmDialog from "@/shared/dialogs/DeleteConfirmDialog.vue";
import ArchiveConfirmDialog from "@/shared/dialogs/ArchiveConfirmDialog.vue";
import PlatformChip from "@/shared/chips/PlatformChip.vue";

import {
  convertDateFromUTC,
  convertDateFromISOWithShortYear,
} from "@/services/utils/DateParser";
import { getUserName } from "@/services/utils/Names";
import { useCampaignStore, Campaign } from "@/store/campaigns/campaigns";
import { useCampaignsDictionariesStore } from "@/store/dictionaries/campaigns";
import { useRouter } from "vue-router";
import { rootGetters } from "@/main";
import { formatNumber } from "@/services/utils/Strings";

const props = defineProps<{
  campaigns: any[];
  isArchiveEnabled: boolean;
  isActiveFirst: boolean;
  isLoading: boolean;
  rowsCount: number;
}>();
const emits = defineEmits(["sortTable", "updateTable"]);
const campaignsStore = useCampaignStore();
const campaignsDictionariesStore = useCampaignsDictionariesStore();
const router = useRouter();

let modifiedId = ref(0);
const confirmVisible = ref({
  delete: false,
  archive: false,
});
const blockedButton = ref({
  delete: false,
  archive: false,
});
const menu = ref();
const expandedRows = ref({});
const items = ref([
  {
    label: "Редактировать",
    icon: "pi pi-pencil",
    archive: "no_archive",
    command: () => {
      router.push({ name: "campaign", params: { id: modifiedId.value } });
    },
  },
  {
    label: "Архивировать",
    icon: "pi pi-inbox",
    archive: "no_archive",
    command: () => {
      confirmVisible.value.archive = true;
    },
  },
  {
    label: "Разархивировать",
    icon: "pi pi-inbox",
    archive: "archive",
    command: () => {
      campaignsStore.unArchiveCampaign(modifiedId.value).then(() => {
        emits("updateTable");
      });
    },
  },
  {
    label: "Дублировать",
    icon: "pi pi-copy",
    archive: "no_archive",
    command: () => {
      campaignsStore.duplicateCampaignId = modifiedId.value;
      router.push({ name: "campaignCreate" });
    },
  },
  {
    label: "Удалить",
    icon: "pi pi-trash",
    archive: "both",
    command: () => {
      confirmVisible.value.delete = true;
    },
  },
]);

const filteredItems = computed(() => {
  return items.value.filter((item) =>
    props.isArchiveEnabled
      ? ["both", "archive"].includes(item.archive)
      : ["both", "no_archive"].includes(item.archive)
  );
});

const toggle = (segmentId: number, event: any) => {
  modifiedId.value = segmentId;
  menu.value.toggle(event);
};

const deleteCampaign = () => {
  blockedButton.value.delete = true;
  campaignsStore
    .deleteCampaign(modifiedId.value)
    .finally(() => {
      emits("updateTable");
      blockedButton.value.delete = false;
      confirmVisible.value.delete = false;
    })
    .catch((err) => {
      if (err.response && err.response.status == 400)
        rootGetters.toast.show("warn", "Ошибка", err.response.data.detail);
    });
};

const archiveCampaign = () => {
  blockedButton.value.archive = true;
  campaignsStore.archiveCampaign(modifiedId.value).finally(() => {
    emits("updateTable");
    blockedButton.value.archive = false;
    confirmVisible.value.archive = false;
  });
};

const sortTable = (event: any) => {
  let orderField = "";
  if (event.sortField)
    orderField = event.sortOrder > 0 ? event.sortField : `-${event.sortField}`;
  emits("sortTable", orderField);
};
</script>

<style lang="scss" scoped>
.table-chip {
  background-color: #eaeaf0;
  padding: 4px;
  border-radius: 4px;
  font-size: 1rem;
  line-height: 13px;
}
</style>
