<template>
  <TopPanel>
    <BreadCrumbs :items="filteredItems" class="bread-left" />
    <SearchInput
      :placeholder="searchPlaceholder"
      class="search-input"
      @update:modelValue="($event) => (searchField = $event)"
      :modelValue="searchField"
    />

    <div class="baged-filter m-r-32">
      <Button
        icon="pi pi-filter-fill"
        id="filter-button"
        severity="secondary"
        aria-label="Bookmark"
        class="m-l-12 f-s-0 toggled"
        @click="emit('toggleFilter')"
        :class="[isFilterEnabled ? 'filter-enabled' : '']"
      >
      </Button>
      <Badge v-if="isFilterSetted" class="bage"></Badge>
    </div>
  </TopPanel>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";

import BreadCrumbs from "@/shared/menu/BreadCrumbs.vue";
import SearchInput from "@/shared/forms/SearchInput.vue";
import Button from "primevue/button";
import TopPanel from "@/widgets/sidebars/TopPanel.vue";
import Badge from "primevue/badge";

defineProps<{
  isFilterEnabled?: boolean;
  isFilterSetted?: boolean;
  searchPlaceholder: string;
}>();
const emit = defineEmits(["setCommonFilter", "toggleFilter"]);

const searchField = ref(null);
const debounceTimeout = ref(0);

const sendItemRequest = () => {
  emit("setCommonFilter", searchField.value);
};

watch(
  () => searchField.value,
  () => {
    if (debounceTimeout.value) clearTimeout(debounceTimeout.value);
    debounceTimeout.value = setTimeout(sendItemRequest, 400);
  },
  { immediate: true }
);

const filteredItems = ref([{ label: "Настройки", archive: "both" }]);

interface BreadCrumbItem {
  label: string;
  archive: string;
  route?: string;
}
</script>

<style lang="scss" scoped>
.entity-create-button {
  flex-basis: 292px;
  flex-shrink: 1;
}

.bread-left {
  margin-left: 18px;
}

.search-input {
  flex: 1;
  flex-basis: 250px;
  flex-shrink: 0;
}

.archive-enabled svg {
  fill: gray;
}

.filter-enabled {
  color: $main-link-color;
}

.baged-filter {
  position: relative;
}

.bage {
  position: absolute;
  right: -1px;
}
</style>
