import { Organization, OrganizationParams } from "@/store/dictionaries/organizations";

export function areOrganizationFieldsValid(org: Organization): boolean {

    if (!org.name || !org.tenant) {
        return false;
    }
    for (const department of org.departments) {
        if (!department.name) {
            return false;
        }
        if (department.roles)
            for (const role of department.roles) {
                if (!role.name) {
                    return false;
                }
            }
    }

    return true;
}

export function setOrganizationsParamsByFilter(params: OrganizationParams, settings: TableSettings) {
    if (settings.orderField) {
        params.order_by = settings.orderField;
    } else {
        params.order_by = '-mtime';
    }

    if (settings.commonFilter) {
        params.name__ilike = settings.commonFilter;
    }
}

interface TableSettings {
    first: number;
    orderField: string;
    commonFilter: string;
}

