import { createStoreConfig } from "../catalog_data/common";
import { Segment } from "./segments";

const endpoints = {
    getItems: { method: "get", url: "/segments/base" },
};

export const useSegmentsDictionaryStore = createStoreConfig<Segment>(endpoints, "segments_dict", {
    order_by: "-mtime",
    in: "id__in",
    pk: "id",
    ilike: "name__ilike",
    sortOptions: { primarySortField: "mtime", primarySortOrder: "desc" }
});