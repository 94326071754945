import { cleanUnderscores } from "../utils/Strings";
import {
    convertDateWithShortYear,
    formatDateToYYYYMMDD,
    convertDateFromString
} from "../utils/DateParser";

import {
    Campaign,
    ServerCampaign,
    ServerCampaignForCreate,
    CampaignSegment,
    CampaignsParams
} from "@/store/campaigns/campaigns";


export function getCampaignName(campaign: Campaign, useDefault: boolean) {
    if (useDefault) return campaign.name;

    let brand = "";
    let shop = "";
    let start_date = "";
    let end_date = "";
    let extra_info = "";
    let strategy = "";

    if (campaign.brand) {
        brand = `${campaign.brand.brand_name}`;
    }

    if (campaign.shop) {
        shop = campaign.shop.shop_short_name || campaign.shop.shop_name;
    }

    if (campaign.date_from) {
        start_date = convertDateWithShortYear(campaign.date_from);
    }

    if (campaign.date_to) {
        end_date = convertDateWithShortYear(campaign.date_to);
    }

    if (campaign.extra_info) {
        extra_info = campaign.extra_info;
    }

    if (campaign.strategy) {
        strategy = campaign.strategy;
    }

    const name = `${brand}_${strategy}_${shop}_${start_date}-${end_date}_${extra_info}`;

    return cleanUnderscores(name);
}

export function isCampaignNameValidated(campaign: Campaign) {
    return (
        isCampaignNameFilled(campaign) &&
        !campaign.brand?.brand_name.includes("_") &&
        !campaign.shop?.shop_name.includes("_")
    );
}

function isCampaignNameFilled(campaign: Campaign) {
    return (
        campaign.brand != null &&
        campaign.shop != null &&
        campaign.date_from != null &&
        campaign.date_to != null
    );
}

export function isSegmentsFilled(campaign: Campaign) {
    let filled = true;
    campaign.campaign_segments.forEach((e) => {
        filled =
            e.ads_cabinet_id != null &&
            e.platform_slug != null &&
            e.segment_id != null;
    });
    return filled;
}

export function isNotValidPeriod(campaign: Campaign) {
    if (campaign.date_to && campaign.date_from) {
        return campaign.date_from > campaign.date_to;
    }
    return true;
}

export function prepareModelToServer(
    campaign: Campaign
): ServerCampaignForCreate | undefined {
    if (campaign.date_from && campaign.date_to)
        return {
            campaign_segments: campaign.campaign_segments,
            campaign: {
                name: campaign.name,
                strategy: campaign.strategy || "",
                extra_info: campaign.extra_info || "",
                brand_pk: campaign.brand?.brand_pk,
                shop_id: campaign.shop?.id,
                start_date: formatDateToYYYYMMDD(campaign.date_from),
                end_date: formatDateToYYYYMMDD(campaign.date_to),
            },
        };

    return undefined;
}

export function convertServerToModel(campaign: ServerCampaign): Campaign {
    const campaignSegments: CampaignSegment[] = [];
    campaign.campaign_segments.forEach((campaignSegment) => {
        campaignSegments.push({
            ads_cabinet_id: campaignSegment.ads_cabinet.id,
            platform_slug: campaignSegment.ads_cabinet.source_slug,
            segment_id: campaignSegment.segment.id,
            is_segment_updatable: campaignSegment.is_segment_updatable,
            updatable_locked: campaignSegment.segment.is_fixed_end_date ? true : false,
            segment: {
                id: campaignSegment.segment.id,
                name: campaignSegment.segment.name,
                is_fixed_end_date: campaignSegment.segment.is_fixed_end_date
            }
        });
    });

    if (campaign.campaign_segments.length == 0) {
        campaignSegments.push({
            segment_id: null as number | null,
            ads_cabinet_id: null as number | null,
            platform_slug: null as string | null,
            is_segment_updatable: false,
            updatable_locked: false,
            segment: null
        })
    }

    return {
        name: campaign.name,
        brand: campaign.brand,
        shop: campaign.shop,
        strategy: campaign.strategy,
        extra_info: campaign.extra_info,
        campaign_segments: campaignSegments,
        date_from: convertDateFromString(campaign.start_date),
        date_to: convertDateFromString(campaign.end_date)
    };
}


export function setCampaignParamsByFilter(params: CampaignsParams, settings: TableSettings) {
    if (settings.orderField) {
        params.order_by = settings.orderField;
    }

    params.is_archived = settings.isArchived;

    if (Object.keys(settings.filter).length > 0) {
        if (settings.filter.name) {
            params.name__ilike = settings.filter.name;
        }

        if (settings.filter.brand.length > 0) {
            params.brand_pk__in = settings.filter.brand.join(",");
        }

        if (settings.filter.shop.length > 0) {
            params.shop_id__in = settings.filter.shop.join(",");
        }

        if (settings.filter.user.length > 0) {
            params.cuser__id__in = settings.filter.user.join(",");
        }

        if (settings.filter.date)
            params.cdate = formatDateToYYYYMMDD(settings.filter.date);

        if (settings.filter.period.start)
            params.start_date = formatDateToYYYYMMDD(settings.filter.period.start);

        if (settings.filter.period.end)
            params.end_date = formatDateToYYYYMMDD(settings.filter.period.end);

        if (settings.filter.ads_cabinet.length > 0) {
            params.ads_cabinet__id__in = settings.filter.ads_cabinet.join(",")
        }

        if (settings.filter.platform.length > 0) {
            params.ads_cabinet__source_slug__in = settings.filter.platform.join(",")
        }

        if (settings.filter.segment.length > 0) {
            params.campaign_segment__segment_id__in = settings.filter.segment.join(",")
        }

        if (settings.filter.updated) {
            params.campaign_segment__is_segment_updatable = settings.filter.updated;
        }

        if (settings.filter.state.length > 0 && !params.is_archived) {
            params.state__in = settings.filter.state.join(",")
        }
    }

    if (settings.commonFilter) {
        params.name__ilike = settings.commonFilter;
    }
}

export interface FilterForm {
    name: string;
    date: Date | null,
    period: {
        start: Date | null,
        end: Date | null,
    },
    state: string[],
    user: number[],
    brand: string[],
    shop: number[],
    platform: string[],
    ads_cabinet: number[],
    segment: number[],
    updated: boolean
}


interface TableSettings {
    filter: FilterForm;
    first: number;
    isArchived: boolean;
    orderField: string;
    commonFilter: string;
}
