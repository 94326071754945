<template>
  <TopPanel>
    <BreadCrumbs :items="items" class="s-bread" />
    <div class="button-container">
      <Button
        label="Сохранить пользователя"
        class="segment-save-button m-l-16 m-r-16"
        @click="createUser"
        :loading="isLoading"
        :disabled="isButtonDisabled"
      />
    </div>
  </TopPanel>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useRoute } from "vue-router";

import BreadCrumbs from "@/shared/menu/BreadCrumbs.vue";
import Button from "primevue/button";
import TopPanel from "@/widgets/sidebars/TopPanel.vue";

defineProps<{
  isLoading: boolean;
  isButtonDisabled?: boolean;
}>();

const emit = defineEmits(["createUser"]);
const route = useRoute();

const nameEdit = computed(() => {
  return route.params.id ? "Редактирование" : "Создание";
});

const items = ref([
  { label: "Справочники" },
  { label: "Список пользователей", route: "users" },
  { label: `${nameEdit.value} пользователя` },
]);

const createUser = () => {
  emit("createUser");
};
</script>

<style lang="scss" scoped>
.s-bread {
  margin-left: 18px;
}

.button-container {
  flex: 1;

  display: flex;
  justify-content: end;

  margin: 0 20px;
}

.segment-save-button {
  width: 240px;
}
</style>
