<template>
  <InnerPageLayout>
    <HandlerAccessDenied :is-denied="isDenied">
      <TopOrganizationPanel
        :isLoading="isLoading"
        @createOrganization="createOrganization"
        :is-button-disabled="isButtonDenied"
      ></TopOrganizationPanel>
      <div class="form-container">
        <OrganizationCreateForm
          :isLoading="isLoading"
          @set-loading="setLoading"
          ref="createFormRef"
        ></OrganizationCreateForm>
      </div>
    </HandlerAccessDenied>
  </InnerPageLayout>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useAuthStore } from "@/store/auth/login";

import InnerPageLayout from "@/widgets/layouts/InnerPageLayout.vue";
import TopOrganizationPanel from "@/entities/dictionaries/organizations/panels/TopOrganizationPanel.vue";
import OrganizationCreateForm from "@/entities/dictionaries/organizations/forms/OrganizationCreateForm.vue";
import HandlerAccessDenied from "@/shared/base/HandlerAccessDenied.vue";

const isLoading = ref(false);
const createFormRef = ref(null);
const authStore = useAuthStore();

const createOrganization = () => {
  if (createFormRef.value) createFormRef.value.createOrganization();
};

const isDenied = computed(() => {
  return authStore.isDeniedForPermissions(
    ["system:read", "system:edit"],
    ["organization:read", "organization:edit"],
    ["dictionaries:read", "dictionaries:edit"]
  );
});

const isButtonDenied = computed(() => {
  return authStore.isDeniedForPermissions(
    ["system:edit"],
    ["organization:edit"],
    ["dictionaries:edit"]
  );
});

const setLoading = (value: boolean) => {
  isLoading.value = value;
};
</script>

<style lang="scss" scoped>
.form-container {
  position: relative;
  top: 77px;
  width: calc(100% - 316px);
}
</style>
