<template>
  <div class="login-container">
    <div class="logo-container">
      <DvLogoComponent />
    </div>
    <div class="login-form-container" v-if="route.query && route.query.nonce">
      <NewPasswordForm @goToLK="handlePasswordRecovered" />
    </div>
    <div class="login-form-container" v-else>
      <PasswordRecoveryForm />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from "vue-router";
import { onMounted, onUnmounted } from "vue";
import { useAuthStore } from "@/store/auth/login";

import DvLogoComponent from "@/shared/auth/DvLogoComponent.vue";
import PasswordRecoveryForm from "@/shared/auth/PasswordRecoveryForm.vue";
import NewPasswordForm from "@/shared/auth/NewPasswordForm.vue";

const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();

const handlePasswordRecovered = () => {
  localStorage.setItem("isPasswordRecovered", "true");
  authStore.setPasswordRecovered(true);
};

const handleStorageChange = (event: any) => {
  if (event.key === "isPasswordRecovered" && event.newValue === "true") {
    goToLK();
    localStorage.removeItem("isPasswordRecovered");
  }
};

const goToLK = () => {
  if (route.query && !route.query.nonce)
    router.push({ name: "personalAccount" });
};

onMounted(() => {
  window.addEventListener("storage", handleStorageChange);
});

onUnmounted(() => {
  window.removeEventListener("storage", handleStorageChange);
});
</script>

<style scoped lang="scss">
.login-container {
  display: flex;
  height: 100vh;
  padding: 20px;
}

.logo-container {
  flex: 1;
  margin-right: 20px;
}

.login-form-container {
  flex: 1;
  background-color: white;
  border-radius: 20px;
  position: relative;
}
</style>
