<template>
  <ListFilterForm
    :is-filter-enabled="props.isFilterEnabled"
    :initial-filter="initialFilter"
    :has-local="true"
    @toggle-filter="emits('toggleFilter')"
    @set-filter="setParentFilter"
    @update-local-filter="setLocalFilter"
  >
    <template #fields="slotProps">
      <div class="flex flex-col">
        <label for="date" class="small-label">Организация</label>
        <CatalogDataBaseInput
          :useIdOnly="true"
          :with-reload="true"
          selectType="organization"
          :isSegmentSetted="false"
          :modelValue="slotProps.filter.organization"
          @update:modelValue="
            ($event) => {
              slotProps.filter.organization = $event;
              slotProps.filter.department = [];
              slotProps.filter.role = [];
            }
          "
        ></CatalogDataBaseInput>
      </div>
      <div class="flex flex-col m-t-8">
        <label for="state" class="small-label">Департамент</label>
        <MultiSelect
          v-model="slotProps.filter.department"
          optionLabel="name"
          :options="departments"
          optionValue="id"
          :disabled="slotProps.filter.organization.length == 0"
          placeholder="Выберите значение"
          @update:modelValue="
            ($event) => {
              slotProps.filter.role = [];
            }
          "
          filter
          showClear
        ></MultiSelect>
      </div>

      <div class="flex flex-col m-t-8">
        <label for="state" class="small-label">Роль</label>
        <MultiSelect
          v-model="slotProps.filter.role"
          optionLabel="name"
          :options="getRoles()"
          optionValue="id"
          :disabled="
            slotProps.filter.department.length == 0 ||
            slotProps.filter.organization.length == 0
          "
          placeholder="Выберите значение"
          filter
          showClear
        ></MultiSelect>
      </div>
    </template>
  </ListFilterForm>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from "vue";

import MultiSelect from "primevue/multiselect";
import CatalogDataBaseInput from "@/widgets/forms/CatalogDataBaseInput.vue";
import ListFilterForm from "@/widgets/forms/ListFilterForm.vue";

import { useOrganizationsDictionaryStore } from "@/store/catalog_data/organizations";
import { Department } from "@/store/dictionaries/organizations";

const props = defineProps<{
  isFilterEnabled: boolean;
}>();
const emits = defineEmits(["toggleFilter", "setFilter"]);
const orgDictionaryStore = useOrganizationsDictionaryStore();
const departments = ref([] as Department[]);
const localFilter = ref<any>(null);

const initialFilter = {
  organization: [] as number[],
  department: [] as number[],
  role: [] as number[],
};

const setDepartments = () => {
  departments.value = [];
  if (localFilter.value && localFilter.value.organization) {
    localFilter.value.organization.forEach((item: number) => {
      const foundItem = orgDictionaryStore.itemList.find(
        (itemDict) => itemDict.id === item
      );
      if (foundItem) {
        departments.value = [...departments.value, ...foundItem.departments];
      }
    });
  }
};

const getRoles = () => {
  let roles: any[] = [];
  if (localFilter.value && localFilter.value.department && departments.value) {
    departments.value.forEach((item) => {
      if (localFilter.value.department.includes(item.id) && item.roles) {
        roles = [...roles, ...item.roles];
      }
    });
  }
  return roles;
};

const setParentFilter = (filter: any, isSetted: boolean) => {
  emits("setFilter", filter, isSetted);
};

const setLocalFilter = (filter: any) => {
  localFilter.value = filter;
  setDepartments();
};
</script>
