import { Operand, FlexiblePeriodItem } from "../segments/filters";

export function convertDateFromUTC(dateString: string) {
    const date = new Date(dateString);

    addHours(date, getUserTimezoneOffset());

    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);  // Месяцы в JavaScript начинаются с 0
    const year = date.getFullYear();

    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);

    return `${day}.${month}.${year} ${hours}:${minutes}`;
}

export function convertDateFromString(dateString: string) {
    return new Date(dateString);
}

export function convertDateFromISOWithShortYear(dateString: string) {
    const date = new Date(dateString);

    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear().toString().substring(2, 4);

    return `${day}.${month}.${year}`;
}

export function convertDateWithShortYear(date: Date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear().toString().substring(2, 4);

    return `${day}.${month}.${year}`;
}

export function formatDateToYYYYMMDD(date: Date): string {
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}

function addHours(date: Date, hours: number) {
    date.setHours(date.getHours() + hours);
}

function getUserTimezoneOffset() {
    const date = new Date();
    const offsetInMinutes = date.getTimezoneOffset();
    const offsetInHours = offsetInMinutes / 60;

    return Math.abs(offsetInHours);
}

export function getPeriodName(num: number, period: string) {
    if (period == 'DAY') return declineDay(num);
    else if (period == 'WEEK') return declineWeek(num);
    else if (period == 'YEAR') return declineYear(num);
    return declineMonth(num);
}

function declineMonth(num: number): string {
    return getDeclension(num, 'месяца', 'месяцев');
}

function declineDay(num: number): string {
    return getDeclension(num, 'дня', 'дней');
}

function declineWeek(num: number): string {
    return getDeclension(num, 'недели', 'недель');
}

function declineYear(num: number): string {
    return getDeclension(num, 'года', 'лет');
}

function getDeclension(num: number, singular: string, few: string): string {
    if (num % 10 === 1 && num % 100 !== 11) {
        return singular;
    } else {
        return few;
    }
}

function getOperandType(operands: Operand[]) {
    if (!operands.length) return "";
    else if (typeof operands[0].value == "object") return "flexible";
    else return "fixed";
}

export function getOperandTypeName(operands: Operand[]) {
    const type = getOperandType(operands);
    if (type == "flexible") return "Смещение от н.в.";
    else if (type == "fixed") return "Точная дата";
    return "";
}

export function getPeriodStr(operands: Operand[]): string {
    const type = getOperandType(operands);
    if (type == "fixed") {
        return getFixedPeriodStr(operands);
    } else if (type == "flexible") {
        return getFlexiblePeriodStr(operands);
    }
    return "";
}

function getFixedPeriodStr(operands: Operand[]): string {
    if (operands.length == 1) {
        return `${convertDateFromISOWithShortYear(
            operands[0].value
        )} - н.в.`;
    } else {
        operands = sortOperands(operands);
        return `${convertDateFromISOWithShortYear(
            operands[0].value
        )} - ${convertDateFromISOWithShortYear(operands[1].value)}`;
    }
}

function getFlexiblePeriodStr(operands: Operand[]): string {
    if (operands.length == 1) {
        return `от ${FlexibleItemUnitToStr(
            operands[0].value
        )} - по н.в.`;
    } else {
        operands = sortOperands(operands);
        return `от ${FlexibleItemUnitToStr(
            operands[0].value
        )} до ${FlexibleItemUnitToStr(
            operands[1].value
        )}`;
    }
}

function sortOperands(operands: Operand[]) {
    return operands.sort((a, b) => {
        if (a.attribute < b.attribute) {
            return -1;
        } else if (a.attribute > b.attribute) {
            return 1;
        } else {
            return 0;
        }
    });
}

function FlexibleItemUnitToStr(item: FlexiblePeriodItem): string {
    let unit = "";

    switch (item.unit) {
        case "DAY":
            unit = "дн."
            break;
        case "WEEK":
            unit = "нед."
            break;
        case "MONTH":
            unit = "мес."
            break;
        case "YEAR":
            unit = "г."
            break;
        default:
            throw new Error("Invalid unit");
    }

    return `${item.interval} ${unit}`;
}

export function getSecondForm(count: number): string {
    const remainder = count % 100;
    if (remainder >= 11 && remainder <= 19) {
        return `секунд`;
    }

    const lastDigit = count % 10;
    switch (lastDigit) {
        case 1:
            return `секунду`;
        case 2:
        case 3:
        case 4:
            return `секунды`;
        default:
            return `секунд`;
    }
}