import { createStoreConfig } from "./common";

const endpoints = {
    getItems: { method: "get", url: "/ads_cabinets" },
};

export const useAdsCabinetsDictionariesStore = createStoreConfig<AdsCabinet>(endpoints, "ads_cabinet_dictionari", {
    order_by: "name",
    in: "id__in",
    pk: "id",
    ilike: "name__ilike",
    nameKeys: ["name", "source.name"],
    sortOptions: { primarySortField: "name" }
});

export interface AdsCabinet {
    id: number,
    is_archived: boolean,
    is_deleted: boolean,
    login: string,
    name: string,
    source_slug: string
}

