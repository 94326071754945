import { AdsCabinetsParams, AdsCabinetForCreate } from "@/store/dictionaries/ads_cabinets";

export function areAdsCabinetFieldsValid(adsCabinet: AdsCabinetForCreate): boolean {
    return !(!adsCabinet.name || !adsCabinet.token || !adsCabinet.source_slug || !adsCabinet.login)
}

export function setAdsCabinetsParamsByFilter(params: AdsCabinetsParams, settings: TableSettings) {
    if (settings.orderField) {
        params.order_by = settings.orderField;
    } else {
        params.order_by = '-mtime';
    }

    if (settings.commonFilter) {
        params.name__ilike = settings.commonFilter;
    }

    if (Object.keys(settings.filter).length > 0) {
        if (settings.filter.name) {
            params.name__ilike = settings.filter.name;
        }

        if (settings.filter.source_slug.length > 0) {
            params.source_slug__in = settings.filter.source_slug.join(",");
        }

        if (settings.filter.is_statistics_required) {
            params.is_statistics_required = settings.filter.is_statistics_required;
        }
    }
}

interface TableSettings {
    first: number;
    orderField: string;
    commonFilter: string;
    filter: FilterForm;
}

export interface FilterForm {
    name: string;
    source_slug: string[];
    is_statistics_required: boolean;
}