<template>
  <div class="auth-form">
    <h1>Восстановление пароля</h1>
    <div class="text">
      <template v-if="!isMailSended"
        >Укажите email, к которому привязан ваш аккаунт, и нажмите кнопку
        "Подтвердить", чтобы инициировать отправку письма со ссылкой на
        восстановление пароля.</template
      >
      <template v-else>
        Мы отправили вам письмо на указанную почту ({{ email }}) со ссылкой на
        восстановление пароля.
      </template>
    </div>
    <div v-if="!isMailSended" class="auth-form-components">
      <div class="flex flex-col input-labeled">
        <label for="username">E-mail</label>
        <InputText
          id="username"
          v-model="email"
          aria-describedby="username-help"
          placeholder="Введите e-mail или номер телефона"
          :invalid="invalid"
        />
      </div>
      <div class="bad-msg" v-if="invalid">
        Мы не нашли учетную запись с этим адресом электронной почты.
      </div>
      <Button
        label="Отправить"
        class="form-button"
        @click="sendMail"
        :disabled="isProgress || email.length == 0"
      />
    </div>
    <div v-else>
      <Button
        label="Вернуться на страницу логина"
        class="form-button m-t-12"
        @click="resetAll"
      />
    </div>
    <div class="after-info" v-if="!isMailSended">
      <div class="forgot">
        <span class="has-account">Есть аккаунт?</span>
        <router-link :to="{ name: 'login' }">Войти</router-link>
      </div>
    </div>
    <div class="after-info m-t-12" v-else>
      <div class="forgot">
        <span class="has-account">Не получили письмо?</span>
        <div v-if="countdown > 0">
          Вы сможете отправить его повторно через: {{ countdown }}
          {{ getSecondForm(countdown) }}.
        </div>
        <a class="pointer" @click="sendMail" v-else>
          Нажмите, чтобы отправить его снова</a
        >
      </div>
    </div>
    <div class="fl-senter m-t-5" v-if="isProgress">
      <ProgressSpinner
        aria-label="Loading"
        style="width: 30px; height: 30px"
        strokeWidth="2"
        fill="var(--surface-ground)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, computed } from "vue";
import { getSecondForm } from "@/services/utils/DateParser";
import { setItemWithExpiry } from "@/services/utils/LocalStorage";

import { useRouter } from "vue-router";
import { useAuthStore } from "@/store/auth/login";
import { rootGetters } from "@/main";

import InputText from "primevue/inputtext";
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";

const authStore = useAuthStore();
const router = useRouter();
const isProgress = ref(false);
const countdown = ref<number>(0);
const email = ref("");
const invalid = ref(false);
const isMailSended = ref(false);
const sendingEnabled = ref(true);

let timer = 0;

const startResendEmailTimer = () => {
  countdown.value = 30;
  timer = setInterval(() => {
    if (countdown.value > 0) {
      countdown.value--;
    } else {
      clearInterval(timer);
      localStorage.removeItem("sendingEnabled");
      sendingEnabled.value = true;
    }
  }, 1000);
};

onMounted(() => {
  if (localStorage.getItem("recoveryEmail") != null) {
    isMailSended.value = true;
    email.value = localStorage.getItem("recoveryEmail");
  }

  if (localStorage.getItem("sendingEnabled")) {
    startResendEmailTimer();
  }
});

watch(
  () => email.value,
  () => {
    invalid.value = false;
  },
  { immediate: true }
);

const sendMail = () => {
  isProgress.value = true;
  invalid.value = false;

  authStore
    .resetPassword(email.value)
    .then(() => {
      setItemWithExpiry("recoveryEmail", email.value, 60);
      setItemWithExpiry("sendingEnabled", "0", 60);
      isMailSended.value = true;
      sendingEnabled.value = false;
      startResendEmailTimer();
    })
    .catch((err) => {
      if (err.response && err.response.status == 404) {
        invalid.value = true;
      } else {
        rootGetters.toast.show(
          "error",
          "Произошла ошибка",
          `Обратитесь к администратору или проверьте соединение с интернетом.`
        );
      }
    })
    .finally(() => {
      isProgress.value = false;
    });
};

const resetAll = () => {
  localStorage.removeItem("recoveryEmail");
  localStorage.removeItem("sendingEnabled");
  router.push({ name: "login" });
};
</script>

<style scoped lang="scss">
.auth-form {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 344px;
}

h1 {
  font-size: 2em;
  font-weight: 600;
  line-height: 142%;
  margin-bottom: 8px;
}

.text {
  margin-top: 8px;
  margin-bottom: 7px;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

label {
  font-weight: 500;
  font-size: 1em;
  line-height: 160%;
  color: #bcbac2;
  margin-bottom: 4px;
  padding-left: 5px;
}

.input-labeled {
  margin-top: 12px;
  width: 100%;
}

:deep(.p-inputtext.p-component) {
  width: 100%;
  font-size: 14px;
  color: $main-font-color;
  padding: 8px 12px;
}

.form-button {
  margin-top: 19px;
  width: 100%;
}

.form-button.m-t-12 {
  margin-top: 12px;
}

.bad-msg {
  font-size: 0.8em;
  font-weight: 400;
  line-height: 130%;
  margin-top: 8px;
  color: red;
}

.after-info {
  .forgot {
    margin-top: 8px;
    font-size: 0.8em;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: -0.2px;

    .has-account {
      color: #b0adb7;
      font-weight: 600;
      padding-right: 5px;
    }

    a {
      color: $main-link-color;
    }
  }
}

.m-t-5 {
  margin-top: 5px;
}

.fl-senter {
  display: flex;
  justify-content: center;
}
</style>
