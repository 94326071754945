<template>
  <div class="auth-form" v-if="!endForm">
    <h1>Установите новый пароль</h1>
    <div class="auth-form-components">
      <div class="form-block password">
        <div class="flex flex-col input-labeled">
          <label for="newPassword">Новый пароль</label>
          <Password
            id="newPassword"
            v-model="password.newPassword"
            placeholder="Введите новый пароль"
            :feedback="false"
            toggleMask
          />
        </div>

        <div class="flex flex-col input-labeled">
          <label for="newPasswordDouble">Подтверждение нового пароля</label>
          <Password
            id="newPasswordDouble"
            v-model="password.doubleNewPassword"
            placeholder="Введите новый пароль"
            :feedback="false"
            toggleMask
            :invalid="invalid"
          />
        </div>
        <div class="bad-msg" v-if="invalid">Пароли не совпадают</div>
        <div class="bad-msg" v-if="invalidLength">
          Пароль должен содержать хотя бы 8 символов
        </div>
      </div>
      <Button
        label="Установить пароль"
        class="form-button"
        @click="changePassword"
        :disabled="isProgress"
      />
    </div>
    <div class="fl-senter m-t-5" v-if="isProgress">
      <ProgressSpinner
        aria-label="Loading"
        style="width: 30px; height: 30px"
        strokeWidth="2"
        fill="var(--surface-ground)"
      />
    </div>
  </div>
  <div class="auth-form" v-else>
    <h1>Новый пароль</h1>
    <div class="m-t-8">Новый пароль успешно сохранен.</div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";

import { useRoute, useRouter } from "vue-router";
import { useAuthStore } from "@/store/auth/login";
import { rootGetters } from "@/main";

import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import Password from "primevue/password";

const emits = defineEmits(["goToLK"]);

const authStore = useAuthStore();
const route = useRoute();
const isProgress = ref(false);
const endForm = ref(false);
const password = ref({
  newPassword: "",
  doubleNewPassword: "",
});
const invalid = ref(false);
const invalidLength = ref(false);
const router = useRouter();
const debounceTimeout = ref(null as number | null);

const sendItemRequest = () => {
  if (password.value.doubleNewPassword != password.value.newPassword) {
    invalid.value = true;
  } else invalid.value = false;
};

watch(
  () => password.value.newPassword,
  () => {
    if (password.value.newPassword.length >= 8) invalidLength.value = false;
  }
);

watch(
  () => password.value.doubleNewPassword,
  () => {
    if (debounceTimeout.value) clearTimeout(debounceTimeout.value);
    debounceTimeout.value = setTimeout(sendItemRequest, 1000);

    if (password.value.doubleNewPassword.length >= 8)
      invalidLength.value = false;
  }
);

watch(
  () => password.value.newPassword,
  () => {
    if (password.value.doubleNewPassword != "") {
      if (debounceTimeout.value) clearTimeout(debounceTimeout.value);
      debounceTimeout.value = setTimeout(sendItemRequest, 1000);
    }
  }
);

function changePassword() {
  invalidLength.value = false;

  if (invalid.value) {
    rootGetters.toast.show(
      "warn",
      "Ошибка сохранения",
      `Проверьте, что пароли совпадают.`
    );
    return;
  }

  if (password.value.newPassword.length < 8) {
    invalidLength.value = true;
    return;
  }

  isProgress.value = true;

  if (route.query && route.query.nonce)
    authStore
      .resetPasswordConfirm(
        route.query.nonce.toString(),
        password.value.newPassword
      )
      .then(() => {
        endForm.value = true;
        localStorage.removeItem("recoveryEmail");
        localStorage.removeItem("sendingEnabled");
        emits("goToLK");
        setTimeout(() => {
          router.push({ name: "personalAccount" });
        }, 3000);
      })
      .catch((err) => {
        if (err.response && err.response.status == 400)
          rootGetters.toast.show("warn", "Ошибка", err.response.data.detail);
        else
          rootGetters.toast.show(
            "error",
            "Произошла ошибка",
            `Обратитесь к администратору или проверьте соединение с интернетом.`
          );
      })
      .finally(() => {
        isProgress.value = false;
      });
}
</script>

<style scoped lang="scss">
.auth-form {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 344px;
}

h1 {
  font-size: 2em;
  font-weight: 600;
  line-height: 142%;
  margin-bottom: 8px;
}

.form-block.password {
  margin-top: 12px;
  margin-bottom: 0;
  padding-top: 0;
}

label {
  color: #a19cab;
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1.43rem;
  padding-bottom: 2px;
  padding-left: 4px;
}

.input-labeled {
  margin-top: 12px;
  width: 100%;
}

:deep(.p-inputtext.p-component) {
  width: 100%;
  font-size: 14px;
  color: $main-font-color;
  padding: 8px 12px;
}

.form-button {
  margin-top: 19px;
  width: 100%;
}

.bad-msg {
  font-size: 10px;
  font-weight: 500;
  line-height: 150%;
  margin-top: 5px;
  color: red;
}

.m-t-5 {
  margin-top: 5px;
}

.fl-senter {
  display: flex;
  justify-content: center;
}
</style>
