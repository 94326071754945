<template>
  <div class="top-sidebar-container">
    <div class="top-sidebar">
      <img src="@/assets/images/logo_black.svg" alt="logo" />
      <div class="user" v-if="store.user" @click="toggle">
        <Avatar icon="pi pi-user" class="dv-avatar" shape="circle" />
        <div class="user-info flex flex-col">
          <div class="user-name">{{ getUserName(store.user) }}</div>
          <div class="user-agency" v-if="store.user.role">
            {{ store.user.role.department.organization.name }}
          </div>
        </div>
      </div>
    </div>

    <OverlayPanel ref="op" showCloseIcon>
      <div v-if="store.user" class="user-panel">
        <div class="user-name">{{ getUserName(store.user) }}</div>
        <div class="user-agency m-t-4" v-if="store.user.role">
          {{ store.user.role.department.organization.name }}
        </div>
        <div class="user-email m-t-4">{{ store.user.email }}</div>
        <div class="user-department m-t-12" v-if="store.user.role">
          {{ store.user.role.department.name }} / {{ store.user.role.name }}
        </div>
        <Button
          label="Выйти из аккаунта"
          class="purple-button m-t-12"
          icon="pi pi-sign-out"
          @click="logout"
        />
      </div>
    </OverlayPanel>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";

import { useAuthStore } from "@/store/auth/login";
import { onMounted } from "vue";

import Avatar from "primevue/avatar";
import Button from "primevue/button";
import OverlayPanel from "primevue/overlaypanel";

import { getUserName } from "@/services/utils/Names";

const store = useAuthStore();
const op = ref();

onMounted(() => {
  if (!store.user && store.isLoggedIn) {
    store.getUserInfo();
  }
});

const toggle = (event: any) => {
  op.value.toggle(event);
};

function logout() {
  store.logout().then(() => {
    location.reload();
  });
}
</script>

<style lang="scss" scoped>
.top-sidebar-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  padding: 12px;
  background-color: $page-background-color;

  z-index: 1000;
}

.top-sidebar {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 48px;

  border-radius: 8px;
  background-color: white;

  padding: 8px 16px;

  img {
    height: 17px;
  }
}

.dv-avatar {
  color: white;
  background-color: #6366f1;
  margin-right: 8px;
  width: 30px;
  height: 30px;
}

.user {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.user-name {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.user-agency {
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;
}

.user-panel {
  min-width: 240px;
  padding: 3px;
}

.user-email {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  color: #a19cab;
}

.user-department {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  padding: 2px 4px;
  border-radius: 4px;
  width: fit-content;
  background-color: #eaeaf0;
}
</style>
